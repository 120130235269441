<template>
  <canvas :class="currentClass" ref="confetti-canvas"></canvas>
</template>

<script>
import JSConfetti from "js-confetti";

export default {
  data() {
    return {
      isPlaying: false,
      jsConfetti: null,
    };
  },
  created() {
    setTimeout(this.setConfettis, 1000);
  },
  computed: {
    currentClass() {
      return this.isPlaying ? "confettis-playing" : "confettis";
    },
  },
  methods: {
    setConfettis() {
      this.jsConfetti = new JSConfetti({
        canvas: this.$refs["confetti-canvas"],
      });
    },
    launchConfettis() {
      if (this.jsConfetti !== null) {
        this.isPlaying = true;
        this.$nextTick(() => {
          this.jsConfetti.addConfetti({
            emojis: ["✨", "🌟", "👍🏼", "🎉", "🙌️", "😃"],
            emojiSize: 80,
          });
          setTimeout(() => {
            this.isPlaying = false;
          }, 3000);
        });
      }
    },
  },
};
</script>

<style scoped>
.confettis {
  position: absolute;
  width: 0px;
  height: 0px;
}

.confettis-playing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events:none;
}
</style>