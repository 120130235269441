<template>
  <div class="share-twitter">
    🌟 I guessed the Emoji Kitchen Game<span v-if="streak > 1"> {{ streak }} times in a row</span>!<br />Can you
    beat me on emoji-kitchen.com? <br /><br />
    <button class="button" @click="share">SHARE ON TWITTER</button>
  </div>
</template>

<script>
export default {
  computed: {
    streak() {
      return this.$store.state.streak.streak;
    },
    twitterHref() {
      const text =
        "🌟 I guessed the Emoji Kitchen Game" +
        (this.streak > 1 ? (" " + this.streak + " times in a row") : "") + "!%0aCan you beat me on emoji-kitchen.com?";
      return  "https://twitter.com/intent/tweet?text=" + text
    },
  },
  methods: {
    share() {
      window.open(this.twitterHref);
    },
  },
};
</script>

<style scoped>
.share-twitter {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.204);
  border-radius: 10px;
  border: grey 1px dashed;
  padding: 2rem;
  font-size: 1.5rem;
  transition: background ease 0.2s;
}

.share-twitter:hover {
  background: rgba(255, 255, 255);
}
</style>