<template>
  <div class="guesser-container">
    <div class="boxes">
      <div
        class="square"
        :class="{ selected: selectedSquare === 'left' }"
        @click="selectSquare('left')"
      >
        <img
          v-if="leftSquareImage.length > 0"
          :src="leftSquareImage"
          alt="Left Square"
          style="width: 100%; height: 100%;"
        />
      </div>
      <div>+</div>
      <div
        class="square"
        :class="{ selected: selectedSquare === 'right' }"
        @click="selectSquare('right')"
      >
        <img
          v-if="rightSquareImage.length > 0"
          :src="rightSquareImage"
          alt="Right Square"
          style="width: 100%; height: 100%;"
        />
      </div>
    </div>
    <div>
      <button
        class="button"
        role="button"
        @click="answer"
        :disabled="guessDisabled"
      >
        GUESS
      </button>
    </div>
    <EmojiPicker class="picker-container" @click="replaceContent($event)" />
  </div>
</template>

<script>
import bus from "../tools/bus";
import EmojiPicker from "./EmojiPicker.vue";

export default {
  data() {
    return {
      selectedSquare: "left",
      leftSquareImage: "",
      rightSquareImage: "",
      leftSquareValue: "",
      rightSquareValue: "",
    };
  },
  created() {
    bus.$on("restart", this.restart);
  },
  computed: {
    guessDisabled() {
      return (
        this.leftSquareImage.length === 0 || this.rightSquareImage.length === 0
      );
    },
  },
  methods: {
    restart() {
      this.selectedSquare = "left";
      this.leftSquareImage = "";
      this.rightSquareImage = "";
      this.leftSquareValue = "";
      this.rightSquareValue = "";
    },
    selectSquare(square) {
      this.selectedSquare = square;
    },
    replaceContent(payload) {
      if (this.selectedSquare === "left") {
        this.leftSquareValue = payload;
        this.leftSquareImage = payload
          ? `https://raw.githubusercontent.com/googlefonts/noto-emoji/main/svg/emoji_u${payload}.svg`
          : "";
        this.selectedSquare = "right";
      } else if (this.selectedSquare === "right") {
        this.rightSquareValue = payload;
        this.rightSquareImage = payload
          ? `https://raw.githubusercontent.com/googlefonts/noto-emoji/main/svg/emoji_u${payload}.svg`
          : "";
      }
    },
    answer() {
      this.$emit("answer", [this.leftSquareValue, this.rightSquareValue]);
    },
  },
  components: {
    EmojiPicker,
  },
};
</script>

<style scoped>
.guesser-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.square {
  width: 100px;
  height: 100px;
  border: 2px solid black;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  padding: 5px;
}

.square.selected {
  border-width: 4px;
  border-color: rgb(86, 86, 255);
}

.picker-container {
  width: 70%;
}

@media screen and (max-width: 680px) {
  .picker-container {
    width: 90%;
  }
}
</style>
