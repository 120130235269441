<template>
  <div class="header">
    <div style="font-size: 2rem; color: #1cb0f6">
      Emoji <img src="../static/img/Logo.png" class="logo-image" /> Kitchen
    </div>
    <div class="title">Which emojis are combined?</div>
    <Streak />
  </div>
</template>

<script>
import Streak from "./Streak.vue";
export default {
  components: {
    Streak,
  },
};
</script>

<style scoped>
.header {
  position: relative;
  margin-top: 1rem;
  font-size: 2.5rem;
  font-family: "Mobilo Medium";
  display: flex;
  flex-direction: column;
  color: black;
}

@media screen and (max-width: 500px) {
  .header {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 450px) {
  .header {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 389px) {
  .header {
    font-size: 1.5rem;
  }
}

.header:hover {
  cursor: default;
}

.logo-image {
  display: inline-block;
  width: 35px;
  vertical-align: middle;
  text-align: center;
}
</style>