import { render, staticRenderFns } from "./Confettis.vue?vue&type=template&id=d4ed853e&scoped=true&"
import script from "./Confettis.vue?vue&type=script&lang=js&"
export * from "./Confettis.vue?vue&type=script&lang=js&"
import style0 from "./Confettis.vue?vue&type=style&index=0&id=d4ed853e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4ed853e",
  null
  
)

export default component.exports