export default [
  "1fa84",
  "1f600",
  "1f603",
  "1f604",
  "1f601",
  "1f606",
  "1f605",
  "1f602",
  "1f923",
  "1f62d",
  "1f609",
  "1f617",
  "1f619",
  "1f61a",
  "1f618",
  "1f970",
  "1f60d",
  "1f929",
  "1f973",
  "1f643",
  "1f642",
  "1f972",
  "1f979",
  "1f60b",
  "1f61b",
  "1f61d",
  "1f61c",
  "1f92a",
  "1f607",
  "1f60a",
  "263a",
  "1f60f",
  "1f60c",
  "1f614",
  "1f611",
  "1f610",
  "1f636",
  "1fae1",
  "1f914",
  "1f92b",
  "1fae2",
  "1f92d",
  "1f971",
  "1f917",
  "1fae3",
  "1f631",
  "1f928",
  "1f9d0",
  "1f612",
  "1f644",
  "1f62e",
  "1f624",
  "1f620",
  "1f621",
  "1f92c",
  "1f97a",
  "1f61f",
  "1f625",
  "1f622",
  "2639",
  "1f641",
  "1fae4",
  "1f615",
  "1f910",
  "1f630",
  "1f628",
  "1f627",
  "1f626",
  "1f62e",
  "1f62f",
  "1f632",
  "1f633",
  "1f92f",
  "1f62c",
  "1f613",
  "1f61e",
  "1f616",
  "1f623",
  "1f629",
  "1f62b",
  "1f635",
  "1fae5",
  "1f634",
  "1f62a",
  "1f924",
  "1f31b",
  "1f31c",
  "1f31a",
  "1f31d",
  "1f31e",
  "1fae0",
  "1f636",
  "1f974",
  "1f975",
  "1f976",
  "1f922",
  "1f92e",
  "1f927",
  "1f912",
  "1f915",
  "1f637",
  "1f920",
  "1f911",
  "1f60e",
  "1f913",
  "1f978",
  "1f925",
  "1f921",
  "1f47b",
  "1f4a9",
  "1f47d",
  "1f47e",
  "1f916",
  "1f383",
  "1f608",
  "1f47f",
  "1f525",
  "1f4ab",
  "2b50",
  "1f31f",
  "1f4a5",
  "1f4af",
  "1fae7",
  "1f573",
  "1f38a",
  "1fa77",
  "2764",
  "1f9e1",
  "1f49b",
  "1f49a",
  "1fa75",
  "1f499",
  "1f49c",
  "1f90e",
  "1fa76",
  "1f5a4",
  "1f90d",
  "2665",
  "1f498",
  "1f49d",
  "1f496",
  "1f497",
  "1f493",
  "1f49e",
  "1f495",
  "1f48c",
  "1f49f",
  "2763",
  "2764",
  "1f494",
  "1f48b",
  "1fac2",
  "1f9e0",
  "1fac0",
  "1fac1",
  "1fa78",
  "1f9a0",
  "1f9b7",
  "1f9b4",
  "1f480",
  "1f440",
  "1f441",
  "1fae6",
  "1f44d",
  "1f937",
  "1f490",
  "1f339",
  "1f940",
  "1f33a",
  "1f337",
  "1f338",
  "1fab7",
  "1f4ae",
  "1f3f5",
  "1fabb",
  "1f33b",
  "1f33c",
  "1f341",
  "1f344",
  "1f331",
  "1f33f",
  "1f343",
  "1f340",
  "1fab4",
  "1f335",
  "1f334",
  "1f333",
  "1f332",
  "1fab9",
  "1fab5",
  "1faa8",
  "26c4",
  "1f30a",
  "1f32c",
  "1f300",
  "1f32a",
  "1f30b",
  "1f3de",
  "1f3d6",
  "26c5",
  "2601",
  "1f327",
  "1f329",
  "1f4a7",
  "2602",
  "26a1",
  "1f308",
  "2604",
  "1f30c",
  "1f30d",
  "1fa90",
  "1f648",
  "1f435",
  "1f981",
  "1f42f",
  "1f431",
  "1f436",
  "1f43a",
  "1f43b",
  "1f428",
  "1f43c",
  "1f42d",
  "1f430",
  "1f98a",
  "1f99d",
  "1f42e",
  "1f437",
  "1f984",
  "1f422",
  "1f438",
  "1f429",
  "1f410",
  "1f98c",
  "1f999",
  "1f9a5",
  "1f994",
  "1f987",
  "1f989",
  "1f426",
  "1f414",
  "1fabf",
  "1f54a",
  "1f9a9",
  "1f427",
  "1f988",
  "1f433",
  "1f41f",
  "1f99e",
  "1f980",
  "1f419",
  "1f9aa",
  "1fab8",
  "1f982",
  "1f577",
  "1f41a",
  "1f40c",
  "1f997",
  "1fab2",
  "1fab3",
  "1f41d",
  "1f41e",
  "1f98b",
  "1f43e",
  "1f353",
  "1f352",
  "1f349",
  "1f34a",
  "1f96d",
  "1f34d",
  "1f34c",
  "1f34b",
  "1f348",
  "1f350",
  "1f95d",
  "1fad2",
  "1fad0",
  "1f347",
  "1f965",
  "1f345",
  "1f336",
  "1fada",
  "1f955",
  "1f360",
  "1f9c5",
  "1f33d",
  "1f966",
  "1f952",
  "1f96c",
  "1fadb",
  "1fad1",
  "1f951",
  "1f9c4",
  "1f954",
  "1fad8",
  "1f330",
  "1f95c",
  "1f35e",
  "1fad3",
  "1f950",
  "1f96f",
  "1f9c7",
  "1f95e",
  "1f373",
  "1f9c0",
  "1f953",
  "1f969",
  "1f356",
  "1f354",
  "1f32d",
  "1f96a",
  "1f968",
  "1f35f",
  "1fad4",
  "1f32e",
  "1f32f",
  "1f959",
  "1f9c6",
  "1f958",
  "1f35d",
  "1f96b",
  "1fad5",
  "1f963",
  "1f957",
  "1f372",
  "1f35b",
  "1f35c",
  "1f363",
  "1f364",
  "1f961",
  "1f35a",
  "1f371",
  "1f359",
  "1f358",
  "1f365",
  "1f960",
  "1f367",
  "1f368",
  "1f366",
  "1f967",
  "1f370",
  "1f382",
  "1f9c1",
  "1f36c",
  "1f36b",
  "1f369",
  "1f36a",
  "1f9c2",
  "1f37f",
  "1f964",
  "1f9cb",
  "1f37c",
  "1f375",
  "2615",
  "1f9c9",
  "1f379",
  "1f376",
  "1f962",
  "1f37d",
  "1f6d1",
  "1f6a8",
  "1f6df",
  "2693",
  "1f697",
  "1f3ce",
  "1f695",
  "1f68c",
  "1f682",
  "1f6f6",
  "1f6f8",
  "1f680",
  "2708",
  "1f3a2",
  "1f3a1",
  "1f3aa",
  "1f3db",
  "1f3df",
  "1f3e0",
  "1f3d5",
  "1f307",
  "1f3dd",
  "1f9f3",
  "1f388",
  "1f380",
  "1f381",
  "1f386",
  "1faa9",
  "1f397",
  "1f947",
  "1f948",
  "1f949",
  "1f3c5",
  "1f396",
  "1f3c6",
  "26bd",
  "26be",
  "1f94e",
  "1f3c0",
  "1f3d0",
  "1f3c8",
  "1f3c9",
  "1f3be",
  "1f945",
  "1f3f8",
  "1f94d",
  "1f3cf",
  "1f3d1",
  "1f3d2",
  "1f94c",
  "1f6f7",
  "1f3bf",
  "26f8",
  "1f6fc",
  "1fa70",
  "1f6f9",
  "26f3",
  "1f3af",
  "1f3f9",
  "1f94f",
  "1fa83",
  "1fa81",
  "1f93f",
  "1f3bd",
  "1f94b",
  "1f94a",
  "1f3b1",
  "1f3d3",
  "1f3b3",
  "265f",
  "1fa80",
  "1f9e9",
  "1f3ae",
  "1f3b2",
  "1f3b0",
  "1f3b4",
  "1f004",
  "1f0cf",
  "1f4f7",
  "1f3a8",
  "1f58c",
  "1f58d",
  "1faa1",
  "1f9f5",
  "1f9f6",
  "1f3b9",
  "1f3b7",
  "1f3ba",
  "1f3b8",
  "1fa95",
  "1f3bb",
  "1fa98",
  "1f941",
  "1fa97",
  "1f3a4",
  "1f3a7",
  "1f39a",
  "1f39b",
  "1f399",
  "1f4fb",
  "1f4fa",
  "1f4fc",
  "1f39e",
  "1f3ac",
  "1f3ad",
  "1f39f",
  "1f4f1",
  "260e",
  "1f4df",
  "1f4e0",
  "1f50c",
  "1f50b",
  "1faab",
  "1f4be",
  "1f4bf",
  "1f4bb",
  "1f5a8",
  "1f4b8",
  "2696",
  "1f4a1",
  "1f6cb",
  "1f9f9",
  "1f9fc",
  "1fa92",
  "1faae",
  "1f9e6",
  "1f393",
  "1f451",
  "1f48d",
  "1f48e",
  "1f460",
  "1f9ea",
  "1f321",
  "1fa7a",
  "1f9ec",
  "1f52d",
  "1f52c",
  "1f9f2",
  "1f6e0",
  "26cf",
  "26d3",
  "1f587",
  "1f4cf",
  "1f5d1",
  "1f58a",
  "2712",
  "270f",
  "1f4da",
  "1f5c3",
  "1f4ca",
  "1f4c8",
  "1f4c9",
  "2702",
  "1f4f0",
  "231b",
  "23f3",
  "23f0",
  "1f514",
  "1f4e3",
  "1f50e",
  "1f52e",
  "1f9ff",
  "1faa4",
  "1f5dd",
  "1f512",
  "2648",
  "2649",
  "264a",
  "264b",
  "264c",
  "264d",
  "264e",
  "264f",
  "2650",
  "2651",
  "2652",
  "2653",
  "26ce",
  "2757",
  "2753",
  "2049",
  "1f198",
  "1f4f4",
  "1f508",
  "26a0",
  "267b",
  "2705",
  "1f195",
  "1f193",
  "1f199",
  "1f197",
  "1f192",
  "1f6ae",
  "262e",
  "262f",
  "267e",
  "2716",
  "2795",
  "2796",
  "2797",
  "27b0",
  "27bf",
  "3030",
  "00a9",
  "00ae",
  "2122",
  "2660",
  "1f5ef",
  "1f4ac"
]