<template>
  <div class="footer">
    <!-- <div class="world">
      <img
        @click="showLocales = !showLocales"
        src="../static/img/World.png"
        width="30px"
      />
    </div> -->
    <!-- <div v-show="showLocales">
      <LocalePicker @change="showLocales = false" />
    </div> -->
    <div class="reminder">
      This game was made using Google's amazing
      <a
        href="https://www.google.fr/search?q=emoji+kitchen"
        target="_blank"
        title="Google's Emoji Kitchen"
        class="link-footer"
        >Emoji Kitchen</a
      >.
    </div>
    <div class="contact">
      <a target="_blank" href="https://twitter.com/Pimeko12" title="Twitter"
        >📧 Contact</a
      >
    </div>
  </div>
</template>

<script>
import LocalePicker from "./LocalePicker.vue";

export default {
  data() {
    return {
      showLocales: false,
    };
  },
  components: {
    LocalePicker,
  },
};
</script>

<style scoped>
.footer {
  padding-top: 3rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.footer:hover {
  cursor: default;
}

.world img {
  transition: all ease 0.2s;
}

.world img:hover {
  cursor: pointer;
  width: 32px;
}

.reminder {
  font-size: 0.9rem;
}

@media screen and (max-width: 700px) {
  .reminder {
    font-size: 0.75rem;
  }
}

.contact {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .contact {
    font-size: 0.8rem;
  }
}

.contact a {
  text-decoration: none;
  color: black;
  /* font-weight: 600; */
}
.contact a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.link-footer {
  color: #1cb0f6;
  text-decoration: none;
  font-weight: 600;
}

.link-footer:hover {
  text-decoration: underline;
}
</style>