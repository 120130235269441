<template>
  <div class="guesser-container">
    <div class="boxes">
      <div class="square">
        <img :src="leftImage" style="width: 100%; height: 100%"/>
      </div>
      <div>
        +
      </div>
      <div class="square">
        <img :src="rightImage" style="width: 100%; height: 100%"/>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../tools/bus";

export default {
  props: ["answer",],
  computed: {
    leftImage() {
      return `https://raw.githubusercontent.com/googlefonts/noto-emoji/main/svg/emoji_u${this.answer.left}.svg`;
    },
    rightImage() {
      return `https://raw.githubusercontent.com/googlefonts/noto-emoji/main/svg/emoji_u${this.answer.right}.svg`;
    },
  },
};
</script>

<style scoped>
.guesser-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.square {
  width: 100px;
  height: 100px;
  border: 2px solid black;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 5px;
}

.square.selected {
  border-width: 4px;
  border-color: rgb(86, 86, 255);
}

.picker-container {
  width: 70%;
}
</style>
