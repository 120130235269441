<template>
  <div class="emoji-picker-container">
    <div class="emoji-search">
      <span class="search-icon">&#x1F50D;</span>
      <input
        type="text"
        v-model="filter"
        placeholder="Search by keyword..."
        class="emoji-input"
        autocomplete="off"
      />
      <span class="clear-icon" @click="clearInput">&#x2716;</span>
    </div>

    <div class="emoji-picker">
      <div class="emoji-grid">
        <div
          v-for="(emoji, i) in filteredEmojis"
          :key="i + '_' + emoji"
          class="emoji-item"
          @click="selectEmoji(emoji)"
        >
          <img
            v-show="shouldShowEmoji(emoji)"
            :src="getEmojiSrc(emoji)"
            alt="Emoji"
            class="emoji-image"
            :title="
              emojiKeywordMapping[emoji]
                .map((e) => {
                  return e.charAt(0).toUpperCase() + e.slice(1);
                })
                .join(', ')
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emojis from "../static/data/emojis";
import emojiKeywordMapping from "../static/data/keywords";
import bus from "../tools/bus";

export default {
  data() {
    return {
      emojis: emojis,
      filter: "",
      emojiKeywordMapping: emojiKeywordMapping,
    };
  },
  created() {
    bus.$on("restart", () => {
      this.filter = "";
    });
  },
  computed: {
    filteredEmojis() {
      if (!this.filter) return this.emojis;
      return this.emojis.filter((emoji) => {
        const keywords = emojiKeywordMapping[emoji];
        if (!keywords) return false;
        return keywords.some((keyword) =>
          keyword.toLowerCase().includes(this.filter.toLowerCase())
        );
      });
    },
  },
  methods: {
    getEmojiSrc(emoji) {
      return `https://raw.githubusercontent.com/googlefonts/noto-emoji/main/svg/emoji_u${emoji}.svg`;
    },
    selectEmoji(emoji) {
      this.$emit("click", emoji);
    },
    clearInput() {
      this.filter = "";
    },
    shouldShowEmoji(emoji) {
      return this.filter
        ? emojiKeywordMapping[emoji] &&
            emojiKeywordMapping[emoji].some((keyword) =>
              keyword.toLowerCase().includes(this.filter.toLowerCase())
            )
        : true;
    },
  },
};
</script>


<style scoped>
.emoji-picker {
  overflow: auto;
  max-height: 300px; /* You can adjust the max height as needed */
  border: 1px solid #ccc;
  padding: 10px;
  background: white;
}

.emoji-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: 5px;
}

.emoji-item {
  cursor: pointer;
}

.emoji-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.emoji-item:hover {
  background-color: #f0f0f0;
}

.emoji-picker-container {
  width: 100%;
}

.emoji-search {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 50px;
  font-size: 16px;
  background: white;
  position: relative;
  flex-grow: 1;
  border: none;
  outline: none;
}

.emoji-input {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.search-icon {
  font-size: 24px;
  color: #ccc;
  cursor: default;
  margin-right: 10px;
}

.clear-icon {
  position: absolute;
  right: 10px; /* adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* vertically center */
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
}

/* Style for the clear icon on hover */
.clear-icon:hover {
  color: red; /* Change to your desired color on hover */
}

.lazy {
  opacity: 0;
  transition: opacity 300ms;
}

.lazy[loaded] {
  opacity: 1;
}
</style>
