const gameAnalytics = require("gameanalytics");

const local = false

const GA = gameAnalytics.GameAnalytics

const RECORD_LOCAL = false
const LOG_ANALYTICS = false

const gameAnalyticsHelper = {
    initialize() {
        if (local && !RECORD_LOCAL) return
        GA.setEnabledInfoLog(LOG_ANALYTICS);
        GA.configureBuild("1.0");
        GA.initialize("b3c62896177c3a4dab9486251de6ed98", "a19833c72ddb88bd05562bf158fb67c370cde808")
    },
    // startGame(gameName) {
    //     if (local && !RECORD_LOCAL) return
    //     GA.addProgressionEvent(gameAnalytics.EGAProgressionStatus.Start, gameName, currentTimezone, "_");
    // },
    // failGame(gameName, attempt) {
    //     if (local && !RECORD_LOCAL) return
    //     GA.addProgressionEvent(gameAnalytics.EGAProgressionStatus.Fail, gameName, currentTimezone, "_", attempt);
    // },
    // completeGame(gameName, streak) {
    //     if (local && !RECORD_LOCAL) return
    //     GA.addProgressionEvent(gameAnalytics.EGAProgressionStatus.Complete, gameName, currentTimezone, streak);
    // },
    sendClickEvent(name) {
        if (local && !RECORD_LOCAL) return
        GA.addDesignEvent("click:" + name, 0)
    },
    sendAnyEvent(value) {
        if (local && !RECORD_LOCAL) return
        GA.addDesignEvent(value, 0)
    },
}

export default gameAnalyticsHelper