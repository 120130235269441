export default {
  "2049": [
    "!",
    "!?",
    "?",
    "exclamation",
    "interrobang",
    "mark",
    "punctuation",
    "question"
  ],
  "2122": [
    "mark",
    "TM",
    "trade mark",
    "trademark"
  ],
  "2601": [
    "cloud",
    "weather"
  ],
  "2602": [
    "clothing",
    "rain",
    "umbrella"
  ],
  "2604": [
    "comet",
    "space"
  ],
  "2615": [
    "beverage",
    "coffee",
    "drink",
    "hot",
    "steaming",
    "tea"
  ],
  "2639": [
    "face",
    "frown",
    "frowning face"
  ],
  "2648": [
    "Aries",
    "ram",
    "zodiac"
  ],
  "2649": [
    "bull",
    "ox",
    "Taurus",
    "zodiac"
  ],
  "2650": [
    "archer",
    "Sagittarius",
    "zodiac"
  ],
  "2651": [
    "Capricorn",
    "goat",
    "zodiac"
  ],
  "2652": [
    "Aquarius",
    "bearer",
    "water",
    "zodiac"
  ],
  "2653": [
    "fish",
    "Pisces",
    "zodiac"
  ],
  "2660": [
    "card",
    "game",
    "spade suit"
  ],
  "2665": [
    "card",
    "game",
    "heart suit"
  ],
  "2693": [
    "anchor",
    "ship",
    "tool"
  ],
  "2696": [
    "balance",
    "justice",
    "Libra",
    "scale",
    "zodiac"
  ],
  "2702": [
    "cutting",
    "scissors",
    "tool"
  ],
  "2705": [
    "✓",
    "button",
    "check",
    "mark"
  ],
  "2708": [
    "aeroplane",
    "airplane"
  ],
  "2712": [
    "black nib",
    "nib",
    "pen"
  ],
  "2716": [
    "×",
    "cancel",
    "multiplication",
    "multiply",
    "sign",
    "x"
  ],
  "2753": [
    "?",
    "mark",
    "punctuation",
    "question",
    "red question mark"
  ],
  "2757": [
    "!",
    "exclamation",
    "mark",
    "punctuation",
    "red exclamation mark"
  ],
  "2763": [
    "exclamation",
    "heart exclamation",
    "mark",
    "punctuation"
  ],
  "2764": [
    "heart",
    "red heart"
  ],
  "2795": [
    "+",
    "math",
    "plus",
    "sign"
  ],
  "2796": [
    "-",
    "−",
    "math",
    "minus",
    "sign"
  ],
  "2797": [
    "÷",
    "divide",
    "division",
    "math",
    "sign"
  ],
  "3030": [
    "dash",
    "punctuation",
    "wavy"
  ],
  "1fa84": [
    "magic",
    "magic wand",
    "witch",
    "wizard"
  ],
  "1f600": [
    "face",
    "grin",
    "grinning face"
  ],
  "1f603": [
    "face",
    "grinning face with big eyes",
    "mouth",
    "open",
    "smile"
  ],
  "1f604": [
    "eye",
    "face",
    "grinning face with smiling eyes",
    "mouth",
    "open",
    "smile"
  ],
  "1f601": [
    "beaming face with smiling eyes",
    "eye",
    "face",
    "grin",
    "smile"
  ],
  "1f606": [
    "face",
    "grinning squinting face",
    "laugh",
    "mouth",
    "satisfied",
    "smile"
  ],
  "1f605": [
    "cold",
    "face",
    "grinning face with sweat",
    "open",
    "smile",
    "sweat"
  ],
  "1f602": [
    "face",
    "face with tears of joy",
    "joy",
    "laugh",
    "tear"
  ],
  "1f923": [
    "face",
    "floor",
    "laugh",
    "rofl",
    "rolling",
    "rolling on the floor laughing",
    "rotfl"
  ],
  "1f62d": [
    "cry",
    "face",
    "loudly crying face",
    "sad",
    "sob",
    "tear"
  ],
  "1f609": [
    "face",
    "wink",
    "winking face"
  ],
  "1f617": [
    "face",
    "kiss",
    "kissing face"
  ],
  "1f619": [
    "eye",
    "face",
    "kiss",
    "kissing face with smiling eyes",
    "smile"
  ],
  "1f61a": [
    "closed",
    "eye",
    "face",
    "kiss",
    "kissing face with closed eyes"
  ],
  "1f618": [
    "face",
    "face blowing a kiss",
    "kiss"
  ],
  "1f970": [
    "adore",
    "crush",
    "hearts",
    "in love",
    "smiling face with hearts"
  ],
  "1f60d": [
    "eye",
    "face",
    "love",
    "smile",
    "smiling face with heart-eyes"
  ],
  "1f929": [
    "eyes",
    "face",
    "grinning",
    "star",
    "star-struck"
  ],
  "1f973": [
    "celebration",
    "hat",
    "horn",
    "party",
    "partying face"
  ],
  "1f643": [
    "face",
    "upside-down"
  ],
  "1f642": [
    "face",
    "slightly smiling face",
    "smile"
  ],
  "1f972": [
    "grateful",
    "proud",
    "relieved",
    "smiling",
    "smiling face with tear",
    "tear",
    "touched"
  ],
  "1f979": [
    "angry",
    "cry",
    "face holding back tears",
    "proud",
    "resist",
    "sad"
  ],
  "1f60b": [
    "delicious",
    "face",
    "face savoring food",
    "savouring",
    "smile",
    "yum"
  ],
  "1f61b": [
    "face",
    "face with tongue",
    "tongue"
  ],
  "1f61d": [
    "eye",
    "face",
    "horrible",
    "squinting face with tongue",
    "taste",
    "tongue"
  ],
  "1f61c": [
    "eye",
    "face",
    "joke",
    "tongue",
    "wink",
    "winking face with tongue"
  ],
  "1f92a": [
    "eye",
    "goofy",
    "large",
    "small",
    "zany face"
  ],
  "1f607": [
    "angel",
    "face",
    "fantasy",
    "halo",
    "innocent",
    "smiling face with halo"
  ],
  "1f60a": [
    "blush",
    "eye",
    "face",
    "smile",
    "smiling face with smiling eyes"
  ],
  "263a": [
    "face",
    "outlined",
    "relaxed",
    "smile",
    "smiling face"
  ],
  "1f60f": [
    "face",
    "smirk",
    "smirking face"
  ],
  "1f60c": [
    "face",
    "relieved"
  ],
  "1f614": [
    "dejected",
    "face",
    "pensive"
  ],
  "1f611": [
    "expressionless",
    "face",
    "inexpressive",
    "meh",
    "unexpressive"
  ],
  "1f610": [
    "deadpan",
    "face",
    "meh",
    "neutral"
  ],
  "1f636": [
    "face",
    "face without mouth",
    "mouth",
    "quiet",
    "silent"
  ],
  "1fae1": [
    "OK",
    "salute",
    "saluting face",
    "sunny",
    "troops",
    "yes"
  ],
  "1f914": [
    "face",
    "thinking"
  ],
  "1f92b": [
    "quiet",
    "shush",
    "shushing face"
  ],
  "1fae2": [
    "amazement",
    "awe",
    "disbelief",
    "embarrass",
    "face with open eyes and hand over mouth",
    "scared",
    "surprise"
  ],
  "1f92d": [
    "face with hand over mouth",
    "whoops"
  ],
  "1f971": [
    "bored",
    "tired",
    "yawn",
    "yawning face"
  ],
  "1f917": [
    "face",
    "hug",
    "hugging",
    "open hands",
    "smiling face",
    "smiling face with open hands"
  ],
  "1fae3": [
    "captivated",
    "face with peeking eye",
    "peep",
    "stare"
  ],
  "1f631": [
    "face",
    "face screaming in fear",
    "fear",
    "munch",
    "scared",
    "scream"
  ],
  "1f928": [
    "distrust",
    "face with raised eyebrow",
    "skeptic"
  ],
  "1f9d0": [
    "face",
    "face with monocle",
    "monocle",
    "stuffy"
  ],
  "1f612": [
    "face",
    "unamused",
    "unhappy"
  ],
  "1f644": [
    "eyeroll",
    "eyes",
    "face",
    "face with rolling eyes",
    "rolling"
  ],
  "1f62e": [
    "face",
    "face with open mouth",
    "mouth",
    "open",
    "sympathy"
  ],
  "1f624": [
    "face",
    "face with steam from nose",
    "triumph",
    "won"
  ],
  "1f620": [
    "anger",
    "angry",
    "face",
    "mad"
  ],
  "1f621": [
    "angry",
    "enraged",
    "face",
    "mad",
    "pouting",
    "rage",
    "red"
  ],
  "1f92c": [
    "face with symbols on mouth",
    "swearing"
  ],
  "1f97a": [
    "begging",
    "mercy",
    "pleading face",
    "puppy eyes"
  ],
  "1f61f": [
    "face",
    "worried"
  ],
  "1f625": [
    "disappointed",
    "face",
    "relieved",
    "sad but relieved face",
    "whew"
  ],
  "1f622": [
    "cry",
    "crying face",
    "face",
    "sad",
    "tear"
  ],
  "1f641": [
    "face",
    "frown",
    "slightly frowning face"
  ],
  "1fae4": [
    "disappointed",
    "face with diagonal mouth",
    "meh",
    "skeptical",
    "unsure"
  ],
  "1f615": [
    "confused",
    "face",
    "meh"
  ],
  "1f910": [
    "face",
    "mouth",
    "zipper",
    "zipper-mouth face"
  ],
  "1f630": [
    "anxious face with sweat",
    "blue",
    "cold",
    "face",
    "rushed",
    "sweat"
  ],
  "1f628": [
    "face",
    "fear",
    "fearful",
    "scared"
  ],
  "1f627": [
    "anguished",
    "face"
  ],
  "1f626": [
    "face",
    "frown",
    "frowning face with open mouth",
    "mouth",
    "open"
  ],
  "1f62f": [
    "face",
    "hushed",
    "stunned",
    "surprised"
  ],
  "1f632": [
    "astonished",
    "face",
    "shocked",
    "totally"
  ],
  "1f633": [
    "dazed",
    "face",
    "flushed"
  ],
  "1f92f": [
    "exploding head",
    "mind blown",
    "shocked"
  ],
  "1f62c": [
    "face",
    "grimace",
    "grimacing face"
  ],
  "1f613": [
    "cold",
    "downcast face with sweat",
    "face",
    "sweat"
  ],
  "1f61e": [
    "disappointed",
    "face"
  ],
  "1f616": [
    "confounded",
    "face"
  ],
  "1f623": [
    "face",
    "persevere",
    "persevering face"
  ],
  "1f629": [
    "face",
    "tired",
    "weary"
  ],
  "1f62b": [
    "face",
    "tired"
  ],
  "1f635": [
    "crossed-out eyes",
    "dead",
    "face",
    "face with crossed-out eyes",
    "knocked out"
  ],
  "1fae5": [
    "depressed",
    "disappear",
    "dotted line face",
    "hide",
    "introvert",
    "invisible"
  ],
  "1f634": [
    "face",
    "good night",
    "sleep",
    "sleeping face",
    "ZZZ"
  ],
  "1f62a": [
    "face",
    "good night",
    "sleep",
    "sleepy face"
  ],
  "1f924": [
    "drooling",
    "face"
  ],
  "1f31b": [
    "face",
    "first quarter moon face",
    "moon",
    "quarter"
  ],
  "1f31c": [
    "face",
    "last quarter moon face",
    "moon",
    "quarter"
  ],
  "1f31a": [
    "face",
    "moon",
    "new moon face"
  ],
  "1f31d": [
    "bright",
    "face",
    "full",
    "moon"
  ],
  "1f31e": [
    "bright",
    "face",
    "sun",
    "sun with face"
  ],
  "1fae0": [
    "disappear",
    "dissolve",
    "liquid",
    "melt",
    "melting face"
  ],
  "1f974": [
    "dizzy",
    "intoxicated",
    "tipsy",
    "uneven eyes",
    "wavy mouth",
    "woozy face"
  ],
  "1f975": [
    "feverish",
    "heat stroke",
    "hot",
    "hot face",
    "red-faced",
    "sweating"
  ],
  "1f976": [
    "blue-faced",
    "cold",
    "cold face",
    "freezing",
    "frostbite",
    "icicles"
  ],
  "1f922": [
    "face",
    "nauseated",
    "vomit"
  ],
  "1f92e": [
    "face vomiting",
    "puke",
    "sick",
    "vomit"
  ],
  "1f927": [
    "face",
    "gesundheit",
    "sneeze",
    "sneezing face"
  ],
  "1f912": [
    "face",
    "face with thermometer",
    "ill",
    "sick",
    "thermometer"
  ],
  "1f915": [
    "bandage",
    "face",
    "face with head-bandage",
    "hurt",
    "injury"
  ],
  "1f637": [
    "cold",
    "doctor",
    "face",
    "face with medical mask",
    "mask",
    "sick"
  ],
  "1f920": [
    "cowboy",
    "cowgirl",
    "face",
    "hat"
  ],
  "1f911": [
    "face",
    "money",
    "money-mouth face",
    "mouth"
  ],
  "1f60e": [
    "bright",
    "cool",
    "face",
    "smiling face with sunglasses",
    "sun",
    "sunglasses"
  ],
  "1f913": [
    "face",
    "geek",
    "nerd"
  ],
  "1f978": [
    "disguise",
    "disguised face",
    "face",
    "glasses",
    "incognito",
    "nose"
  ],
  "1f925": [
    "face",
    "lie",
    "lying face",
    "pinocchio"
  ],
  "1f921": [
    "clown",
    "face"
  ],
  "1f47b": [
    "creature",
    "face",
    "fairy tale",
    "fantasy",
    "ghost",
    "monster"
  ],
  "1f4a9": [
    "dung",
    "face",
    "monster",
    "pile of poo",
    "poo",
    "poop"
  ],
  "1f47d": [
    "alien",
    "creature",
    "extraterrestrial",
    "face",
    "fantasy",
    "ufo"
  ],
  "1f47e": [
    "alien",
    "creature",
    "extraterrestrial",
    "face",
    "monster",
    "ufo"
  ],
  "1f916": [
    "face",
    "monster",
    "robot"
  ],
  "1f383": [
    "celebration",
    "halloween",
    "jack",
    "jack-o-lantern",
    "lantern"
  ],
  "1f608": [
    "face",
    "fairy tale",
    "fantasy",
    "horns",
    "smile",
    "smiling face with horns"
  ],
  "1f47f": [
    "angry face with horns",
    "demon",
    "devil",
    "face",
    "fantasy",
    "imp"
  ],
  "1f525": [
    "fire",
    "flame",
    "tool"
  ],
  "1f4ab": [
    "comic",
    "dizzy",
    "star"
  ],
  "2b50": [
    "star"
  ],
  "1f31f": [
    "glittery",
    "glow",
    "glowing star",
    "shining",
    "sparkle",
    "star"
  ],
  "1f4a5": [
    "boom",
    "collision",
    "comic"
  ],
  "1f4af": [
    "100",
    "full",
    "hundred",
    "hundred points",
    "score"
  ],
  "1fae7": [
    "bubbles",
    "burp",
    "clean",
    "soap",
    "underwater"
  ],
  "1f573": [
    "hole"
  ],
  "1f38a": [
    "ball",
    "celebration",
    "confetti"
  ],
  "1fa77": [
    "cute",
    "heart",
    "like",
    "love",
    "pink"
  ],
  "1f9e1": [
    "orange",
    "orange heart"
  ],
  "1f49b": [
    "yellow",
    "yellow heart"
  ],
  "1f49a": [
    "green",
    "green heart"
  ],
  "1fa75": [
    "cyan",
    "heart",
    "light blue",
    "light blue heart",
    "teal"
  ],
  "1f499": [
    "blue",
    "blue heart"
  ],
  "1f49c": [
    "purple",
    "purple heart"
  ],
  "1f90e": [
    "brown",
    "heart"
  ],
  "1fa76": [
    "gray",
    "grey heart",
    "heart",
    "silver",
    "slate"
  ],
  "1f5a4": [
    "black",
    "black heart",
    "evil",
    "wicked"
  ],
  "1f90d": [
    "heart",
    "white"
  ],
  "1f498": [
    "arrow",
    "cupid",
    "heart with arrow"
  ],
  "1f49d": [
    "heart with ribbon",
    "ribbon",
    "valentine"
  ],
  "1f496": [
    "excited",
    "sparkle",
    "sparkling heart"
  ],
  "1f497": [
    "excited",
    "growing",
    "growing heart",
    "nervous",
    "pulse"
  ],
  "1f493": [
    "beating",
    "beating heart",
    "heartbeat",
    "pulsating"
  ],
  "1f49e": [
    "revolving",
    "revolving hearts"
  ],
  "1f495": [
    "love",
    "two hearts"
  ],
  "1f48c": [
    "heart",
    "letter",
    "love",
    "mail"
  ],
  "1f49f": [
    "heart",
    "heart decoration"
  ],
  "1f494": [
    "break",
    "broken",
    "broken heart"
  ],
  "1f48b": [
    "kiss",
    "kiss mark",
    "lips"
  ],
  "1fac2": [
    "goodbye",
    "hello",
    "hug",
    "people hugging",
    "thanks"
  ],
  "1f9e0": [
    "brain",
    "intelligent"
  ],
  "1fac0": [
    "anatomical",
    "cardiology",
    "heart",
    "organ",
    "pulse"
  ],
  "1fac1": [
    "breath",
    "exhalation",
    "inhalation",
    "lungs",
    "organ",
    "respiration"
  ],
  "1fa78": [
    "bleed",
    "blood donation",
    "drop of blood",
    "injury",
    "medicine",
    "menstruation"
  ],
  "1f9a0": [
    "amoeba",
    "bacteria",
    "microbe",
    "virus"
  ],
  "1f9b7": [
    "dentist",
    "tooth"
  ],
  "1f9b4": [
    "bone",
    "skeleton"
  ],
  "1f480": [
    "death",
    "face",
    "fairy tale",
    "monster",
    "skull"
  ],
  "1f440": [
    "eye",
    "eyes",
    "face"
  ],
  "1f441": [
    "body",
    "eye"
  ],
  "1fae6": [
    "anxious",
    "biting lip",
    "fear",
    "flirting",
    "nervous",
    "uncomfortable",
    "worried"
  ],
  "1f44d": [
    "+1",
    "hand",
    "thumb",
    "thumbs up",
    "up"
  ],
  "1f937": [
    "doubt",
    "ignorance",
    "indifference",
    "person shrugging",
    "shrug"
  ],
  "1f490": [
    "bouquet",
    "flower"
  ],
  "1f339": [
    "flower",
    "rose"
  ],
  "1f940": [
    "flower",
    "wilted"
  ],
  "1f33a": [
    "flower",
    "hibiscus"
  ],
  "1f337": [
    "flower",
    "tulip"
  ],
  "1f338": [
    "blossom",
    "cherry",
    "flower"
  ],
  "1fab7": [
    "Buddhism",
    "flower",
    "Hinduism",
    "lotus",
    "purity"
  ],
  "1f4ae": [
    "flower",
    "white flower"
  ],
  "1f3f5": [
    "plant",
    "rosette"
  ],
  "1fabb": [
    "bluebonnet",
    "flower",
    "hyacinth",
    "lavender",
    "lupine",
    "snapdragon"
  ],
  "1f33b": [
    "flower",
    "sun",
    "sunflower"
  ],
  "1f33c": [
    "blossom",
    "flower"
  ],
  "1f341": [
    "falling",
    "leaf",
    "maple"
  ],
  "1f344": [
    "mushroom",
    "toadstool"
  ],
  "1f331": [
    "seedling",
    "young"
  ],
  "1f33f": [
    "herb",
    "leaf"
  ],
  "1f343": [
    "blow",
    "flutter",
    "leaf",
    "leaf fluttering in wind",
    "wind"
  ],
  "1f340": [
    "4",
    "clover",
    "four",
    "four-leaf clover",
    "leaf"
  ],
  "1fab4": [
    "boring",
    "grow",
    "house",
    "nurturing",
    "plant",
    "potted plant",
    "useless"
  ],
  "1f335": [
    "cactus",
    "plant"
  ],
  "1f334": [
    "palm",
    "tree"
  ],
  "1f333": [
    "deciduous",
    "shedding",
    "tree"
  ],
  "1f332": [
    "evergreen tree",
    "tree"
  ],
  "1fab9": [
    "empty nest",
    "nesting"
  ],
  "1fab5": [
    "log",
    "lumber",
    "timber",
    "wood"
  ],
  "1faa8": [
    "boulder",
    "heavy",
    "rock",
    "solid",
    "stone"
  ],
  "26c4": [
    "cold",
    "snow",
    "snowman",
    "snowman without snow"
  ],
  "1f30a": [
    "ocean",
    "water",
    "wave"
  ],
  "1f32c": [
    "blow",
    "cloud",
    "face",
    "wind"
  ],
  "1f300": [
    "cyclone",
    "dizzy",
    "hurricane",
    "twister",
    "typhoon"
  ],
  "1f32a": [
    "cloud",
    "tornado",
    "whirlwind"
  ],
  "1f30b": [
    "eruption",
    "mountain",
    "volcano"
  ],
  "1f3de": [
    "national park",
    "park"
  ],
  "1f3d6": [
    "beach",
    "beach with umbrella",
    "umbrella"
  ],
  "26c5": [
    "cloud",
    "sun",
    "sun behind cloud"
  ],
  "1f327": [
    "cloud",
    "cloud with rain",
    "rain"
  ],
  "1f329": [
    "cloud",
    "cloud with lightning",
    "lightning"
  ],
  "1f4a7": [
    "cold",
    "comic",
    "drop",
    "droplet",
    "sweat"
  ],
  "26a1": [
    "danger",
    "electric",
    "high voltage",
    "lightning",
    "voltage",
    "zap"
  ],
  "1f308": [
    "rain",
    "rainbow"
  ],
  "1f30c": [
    "milky way",
    "space"
  ],
  "1f30d": [
    "Africa",
    "earth",
    "Europe",
    "globe",
    "globe showing Europe-Africa",
    "world"
  ],
  "1fa90": [
    "ringed planet",
    "saturn",
    "saturnine"
  ],
  "1f648": [
    "evil",
    "face",
    "forbidden",
    "monkey",
    "see",
    "see-no-evil monkey"
  ],
  "1f435": [
    "face",
    "monkey"
  ],
  "1f981": [
    "face",
    "Leo",
    "lion",
    "zodiac"
  ],
  "1f42f": [
    "face",
    "tiger"
  ],
  "1f431": [
    "cat",
    "face",
    "pet"
  ],
  "1f436": [
    "dog",
    "face",
    "pet"
  ],
  "1f43a": [
    "face",
    "wolf"
  ],
  "1f43b": [
    "bear",
    "face"
  ],
  "1f428": [
    "face",
    "koala",
    "marsupial"
  ],
  "1f43c": [
    "face",
    "panda"
  ],
  "1f42d": [
    "face",
    "mouse"
  ],
  "1f430": [
    "bunny",
    "face",
    "pet",
    "rabbit"
  ],
  "1f98a": [
    "face",
    "fox"
  ],
  "1f99d": [
    "curious",
    "raccoon",
    "sly"
  ],
  "1f42e": [
    "cow",
    "face"
  ],
  "1f437": [
    "face",
    "pig"
  ],
  "1f984": [
    "face",
    "unicorn"
  ],
  "1f422": [
    "terrapin",
    "tortoise",
    "turtle"
  ],
  "1f438": [
    "face",
    "frog"
  ],
  "1f429": [
    "dog",
    "poodle"
  ],
  "1f410": [
    "Capricorn",
    "goat",
    "zodiac"
  ],
  "1f98c": [
    "deer"
  ],
  "1f999": [
    "alpaca",
    "guanaco",
    "llama",
    "vicuña",
    "wool"
  ],
  "1f9a5": [
    "lazy",
    "sloth",
    "slow"
  ],
  "1f994": [
    "hedgehog",
    "spiny"
  ],
  "1f987": [
    "bat",
    "vampire"
  ],
  "1f989": [
    "bird",
    "owl",
    "wise"
  ],
  "1f426": [
    "bird"
  ],
  "1f414": [
    "bird",
    "chicken"
  ],
  "1fabf": [
    "bird",
    "fowl",
    "goose",
    "honk",
    "silly"
  ],
  "1f54a": [
    "bird",
    "dove",
    "fly",
    "peace"
  ],
  "1f9a9": [
    "flamboyant",
    "flamingo",
    "tropical"
  ],
  "1f427": [
    "bird",
    "penguin"
  ],
  "1f988": [
    "fish",
    "shark"
  ],
  "1f433": [
    "face",
    "spouting",
    "whale"
  ],
  "1f41f": [
    "fish",
    "Pisces",
    "zodiac"
  ],
  "1f99e": [
    "bisque",
    "claws",
    "lobster",
    "seafood"
  ],
  "1f980": [
    "Cancer",
    "crab",
    "zodiac"
  ],
  "1f419": [
    "octopus"
  ],
  "1f9aa": [
    "diving",
    "oyster",
    "pearl"
  ],
  "1fab8": [
    "coral",
    "ocean",
    "reef"
  ],
  "1f982": [
    "scorpio",
    "Scorpio",
    "scorpion",
    "zodiac"
  ],
  "1f577": [
    "insect",
    "spider"
  ],
  "1f41a": [
    "shell",
    "spiral"
  ],
  "1f40c": [
    "snail"
  ],
  "1f997": [
    "cricket",
    "grasshopper"
  ],
  "1fab2": [
    "beetle",
    "bug",
    "insect"
  ],
  "1fab3": [
    "cockroach",
    "insect",
    "pest",
    "roach"
  ],
  "1f41d": [
    "bee",
    "honeybee",
    "insect"
  ],
  "1f41e": [
    "beetle",
    "insect",
    "lady beetle",
    "ladybird",
    "ladybug"
  ],
  "1f98b": [
    "butterfly",
    "insect",
    "pretty"
  ],
  "1f43e": [
    "feet",
    "paw",
    "paw prints",
    "print"
  ],
  "1f353": [
    "berry",
    "fruit",
    "strawberry"
  ],
  "1f352": [
    "berries",
    "cherries",
    "cherry",
    "fruit",
    "red"
  ],
  "1f349": [
    "fruit",
    "watermelon"
  ],
  "1f34a": [
    "fruit",
    "orange",
    "tangerine"
  ],
  "1f96d": [
    "fruit",
    "mango",
    "tropical"
  ],
  "1f34d": [
    "fruit",
    "pineapple"
  ],
  "1f34c": [
    "banana",
    "fruit"
  ],
  "1f34b": [
    "citrus",
    "fruit",
    "lemon"
  ],
  "1f348": [
    "fruit",
    "melon"
  ],
  "1f350": [
    "fruit",
    "pear"
  ],
  "1f95d": [
    "food",
    "fruit",
    "kiwi"
  ],
  "1fad2": [
    "food",
    "olive"
  ],
  "1fad0": [
    "berry",
    "bilberry",
    "blue",
    "blueberries",
    "blueberry"
  ],
  "1f347": [
    "fruit",
    "grape",
    "grapes"
  ],
  "1f965": [
    "coconut",
    "palm",
    "piña colada"
  ],
  "1f345": [
    "fruit",
    "tomato",
    "vegetable"
  ],
  "1f336": [
    "hot",
    "pepper"
  ],
  "1fada": [
    "beer",
    "ginger root",
    "root",
    "spice"
  ],
  "1f955": [
    "carrot",
    "food",
    "vegetable"
  ],
  "1f360": [
    "potato",
    "roasted",
    "sweet"
  ],
  "1f9c5": [
    "flavoring",
    "onion"
  ],
  "1f33d": [
    "corn",
    "ear",
    "ear of corn",
    "maize",
    "maze"
  ],
  "1f966": [
    "broccoli",
    "wild cabbage"
  ],
  "1f952": [
    "cucumber",
    "food",
    "pickle",
    "vegetable"
  ],
  "1f96c": [
    "bok choy",
    "cabbage",
    "kale",
    "leafy green",
    "lettuce"
  ],
  "1fadb": [
    "beans",
    "edamame",
    "legume",
    "pea",
    "pod",
    "vegetable"
  ],
  "1fad1": [
    "bell pepper",
    "capsicum",
    "pepper",
    "vegetable"
  ],
  "1f951": [
    "avocado",
    "food",
    "fruit"
  ],
  "1f9c4": [
    "flavoring",
    "garlic"
  ],
  "1f954": [
    "food",
    "potato",
    "vegetable"
  ],
  "1fad8": [
    "beans",
    "food",
    "kidney",
    "legume"
  ],
  "1f330": [
    "chestnut",
    "plant"
  ],
  "1f95c": [
    "food",
    "nut",
    "peanut",
    "peanuts",
    "vegetable"
  ],
  "1f35e": [
    "bread",
    "loaf"
  ],
  "1fad3": [
    "arepa",
    "flatbread",
    "lavash",
    "naan",
    "pita"
  ],
  "1f950": [
    "bread",
    "breakfast",
    "croissant",
    "food",
    "french",
    "roll"
  ],
  "1f96f": [
    "bagel",
    "bakery",
    "breakfast",
    "schmear"
  ],
  "1f9c7": [
    "breakfast",
    "indecisive",
    "iron",
    "waffle"
  ],
  "1f95e": [
    "breakfast",
    "crêpe",
    "food",
    "hotcake",
    "pancake",
    "pancakes"
  ],
  "1f373": [
    "breakfast",
    "cooking",
    "egg",
    "frying",
    "pan"
  ],
  "1f9c0": [
    "cheese",
    "cheese wedge"
  ],
  "1f953": [
    "bacon",
    "breakfast",
    "food",
    "meat"
  ],
  "1f969": [
    "chop",
    "cut of meat",
    "lambchop",
    "porkchop",
    "steak"
  ],
  "1f356": [
    "bone",
    "meat",
    "meat on bone"
  ],
  "1f354": [
    "burger",
    "hamburger"
  ],
  "1f32d": [
    "frankfurter",
    "hot dog",
    "hotdog",
    "sausage"
  ],
  "1f96a": [
    "bread",
    "sandwich"
  ],
  "1f968": [
    "pretzel",
    "twisted"
  ],
  "1f35f": [
    "french",
    "fries"
  ],
  "1fad4": [
    "mexican",
    "tamale",
    "wrapped"
  ],
  "1f32e": [
    "mexican",
    "taco"
  ],
  "1f32f": [
    "burrito",
    "mexican",
    "wrap"
  ],
  "1f959": [
    "falafel",
    "flatbread",
    "food",
    "gyro",
    "kebab",
    "stuffed"
  ],
  "1f9c6": [
    "chickpea",
    "falafel",
    "meatball"
  ],
  "1f958": [
    "casserole",
    "food",
    "paella",
    "pan",
    "shallow",
    "shallow pan of food"
  ],
  "1f35d": [
    "pasta",
    "spaghetti"
  ],
  "1f96b": [
    "can",
    "canned food"
  ],
  "1fad5": [
    "cheese",
    "chocolate",
    "fondue",
    "melted",
    "pot",
    "Swiss"
  ],
  "1f963": [
    "bowl with spoon",
    "breakfast",
    "cereal",
    "congee"
  ],
  "1f957": [
    "food",
    "green",
    "salad"
  ],
  "1f372": [
    "pot",
    "pot of food",
    "stew"
  ],
  "1f35b": [
    "curry",
    "rice"
  ],
  "1f35c": [
    "bowl",
    "noodle",
    "ramen",
    "steaming"
  ],
  "1f363": [
    "sushi"
  ],
  "1f364": [
    "fried",
    "prawn",
    "shrimp",
    "tempura"
  ],
  "1f961": [
    "oyster pail",
    "takeout box"
  ],
  "1f35a": [
    "cooked",
    "rice"
  ],
  "1f371": [
    "bento",
    "box"
  ],
  "1f359": [
    "ball",
    "Japanese",
    "rice"
  ],
  "1f358": [
    "cracker",
    "rice"
  ],
  "1f365": [
    "cake",
    "fish",
    "fish cake with swirl",
    "pastry",
    "swirl"
  ],
  "1f960": [
    "fortune cookie",
    "prophecy"
  ],
  "1f367": [
    "dessert",
    "ice",
    "shaved",
    "sweet"
  ],
  "1f368": [
    "cream",
    "dessert",
    "ice",
    "sweet"
  ],
  "1f366": [
    "cream",
    "dessert",
    "ice",
    "icecream",
    "soft",
    "sweet"
  ],
  "1f967": [
    "filling",
    "pastry",
    "pie"
  ],
  "1f370": [
    "cake",
    "dessert",
    "pastry",
    "shortcake",
    "slice",
    "sweet"
  ],
  "1f382": [
    "birthday",
    "cake",
    "celebration",
    "dessert",
    "pastry",
    "sweet"
  ],
  "1f9c1": [
    "bakery",
    "cupcake",
    "sweet"
  ],
  "1f36c": [
    "candy",
    "dessert",
    "sweet"
  ],
  "1f36b": [
    "bar",
    "chocolate",
    "dessert",
    "sweet"
  ],
  "1f369": [
    "breakfast",
    "dessert",
    "donut",
    "doughnut",
    "sweet"
  ],
  "1f36a": [
    "cookie",
    "dessert",
    "sweet"
  ],
  "1f9c2": [
    "condiment",
    "salt",
    "shaker"
  ],
  "1f37f": [
    "popcorn"
  ],
  "1f964": [
    "cup with straw",
    "juice",
    "soda"
  ],
  "1f9cb": [
    "bubble",
    "milk",
    "pearl",
    "tea"
  ],
  "1f37c": [
    "baby",
    "bottle",
    "drink",
    "milk"
  ],
  "1f375": [
    "beverage",
    "cup",
    "drink",
    "tea",
    "teacup",
    "teacup without handle"
  ],
  "1f9c9": [
    "drink",
    "mate"
  ],
  "1f379": [
    "bar",
    "drink",
    "tropical"
  ],
  "1f376": [
    "bar",
    "beverage",
    "bottle",
    "cup",
    "drink",
    "sake"
  ],
  "1f962": [
    "chopsticks",
    "hashi"
  ],
  "1f37d": [
    "cooking",
    "fork",
    "fork and knife with plate",
    "knife",
    "plate"
  ],
  "1f6d1": [
    "octagonal",
    "sign",
    "stop"
  ],
  "1f6a8": [
    "beacon",
    "car",
    "light",
    "police",
    "revolving"
  ],
  "1f6df": [
    "float",
    "life preserver",
    "life saver",
    "rescue",
    "ring buoy",
    "safety"
  ],
  "1f697": [
    "automobile",
    "car"
  ],
  "1f3ce": [
    "car",
    "racing"
  ],
  "1f695": [
    "taxi",
    "vehicle"
  ],
  "1f68c": [
    "bus",
    "vehicle"
  ],
  "1f682": [
    "engine",
    "locomotive",
    "railway",
    "steam",
    "train"
  ],
  "1f6f6": [
    "boat",
    "canoe"
  ],
  "1f6f8": [
    "flying saucer",
    "UFO"
  ],
  "1f680": [
    "rocket",
    "space"
  ],
  "1f3a2": [
    "amusement park",
    "coaster",
    "roller"
  ],
  "1f3a1": [
    "amusement park",
    "ferris",
    "wheel"
  ],
  "1f3aa": [
    "circus",
    "tent"
  ],
  "1f3db": [
    "classical",
    "classical building"
  ],
  "1f3df": [
    "stadium"
  ],
  "1f3e0": [
    "home",
    "house"
  ],
  "1f3d5": [
    "camping"
  ],
  "1f307": [
    "dusk",
    "sun",
    "sunset"
  ],
  "1f3dd": [
    "desert",
    "island"
  ],
  "1f9f3": [
    "luggage",
    "packing",
    "travel"
  ],
  "1f388": [
    "balloon",
    "celebration"
  ],
  "1f380": [
    "celebration",
    "ribbon"
  ],
  "1f381": [
    "box",
    "celebration",
    "gift",
    "present",
    "wrapped"
  ],
  "1f386": [
    "celebration",
    "fireworks"
  ],
  "1faa9": [
    "dance",
    "disco",
    "glitter",
    "mirror ball",
    "party"
  ],
  "1f397": [
    "celebration",
    "reminder",
    "ribbon"
  ],
  "1f947": [
    "1st place medal",
    "first",
    "gold",
    "medal"
  ],
  "1f948": [
    "2nd place medal",
    "medal",
    "second",
    "silver"
  ],
  "1f949": [
    "3rd place medal",
    "bronze",
    "medal",
    "third"
  ],
  "1f3c5": [
    "medal",
    "sports medal"
  ],
  "1f396": [
    "celebration",
    "medal",
    "military"
  ],
  "1f3c6": [
    "prize",
    "trophy"
  ],
  "26bd": [
    "ball",
    "football",
    "soccer"
  ],
  "26be": [
    "ball",
    "baseball"
  ],
  "1f94e": [
    "ball",
    "glove",
    "softball",
    "underarm"
  ],
  "1f3c0": [
    "ball",
    "basketball",
    "hoop"
  ],
  "1f3d0": [
    "ball",
    "game",
    "volleyball"
  ],
  "1f3c8": [
    "american",
    "ball",
    "football"
  ],
  "1f3c9": [
    "ball",
    "football",
    "rugby"
  ],
  "1f3be": [
    "ball",
    "racquet",
    "tennis"
  ],
  "1f945": [
    "goal",
    "net"
  ],
  "1f3f8": [
    "badminton",
    "birdie",
    "game",
    "racquet",
    "shuttlecock"
  ],
  "1f94d": [
    "ball",
    "goal",
    "lacrosse",
    "stick"
  ],
  "1f3cf": [
    "ball",
    "bat",
    "cricket game",
    "game"
  ],
  "1f3d1": [
    "ball",
    "field",
    "game",
    "hockey",
    "stick"
  ],
  "1f3d2": [
    "game",
    "hockey",
    "ice",
    "puck",
    "stick"
  ],
  "1f94c": [
    "curling stone",
    "game",
    "rock"
  ],
  "1f6f7": [
    "sled",
    "sledge",
    "sleigh"
  ],
  "1f3bf": [
    "ski",
    "skis",
    "snow"
  ],
  "26f8": [
    "ice",
    "skate"
  ],
  "1f6fc": [
    "roller",
    "skate"
  ],
  "1fa70": [
    "ballet",
    "ballet shoes",
    "dance"
  ],
  "1f6f9": [
    "board",
    "skateboard"
  ],
  "26f3": [
    "flag in hole",
    "golf",
    "hole"
  ],
  "1f3af": [
    "bullseye",
    "dart",
    "direct hit",
    "game",
    "hit",
    "target"
  ],
  "1f3f9": [
    "archer",
    "arrow",
    "bow",
    "bow and arrow",
    "Sagittarius",
    "zodiac"
  ],
  "1f94f": [
    "flying disc",
    "ultimate"
  ],
  "1fa83": [
    "boomerang",
    "rebound",
    "repercussion"
  ],
  "1fa81": [
    "fly",
    "kite",
    "soar"
  ],
  "1f93f": [
    "diving",
    "diving mask",
    "scuba",
    "snorkeling"
  ],
  "1f3bd": [
    "athletics",
    "running",
    "sash",
    "shirt"
  ],
  "1f94b": [
    "judo",
    "karate",
    "martial arts",
    "martial arts uniform",
    "taekwondo",
    "uniform"
  ],
  "1f94a": [
    "boxing",
    "glove"
  ],
  "1f3b1": [
    "8",
    "ball",
    "billiard",
    "eight",
    "game",
    "pool 8 ball"
  ],
  "1f3d3": [
    "ball",
    "bat",
    "game",
    "paddle",
    "ping pong",
    "table tennis"
  ],
  "1f3b3": [
    "ball",
    "bowling",
    "game"
  ],
  "265f": [
    "chess",
    "chess pawn",
    "dupe",
    "expendable"
  ],
  "1fa80": [
    "fluctuate",
    "toy",
    "yo-yo"
  ],
  "1f9e9": [
    "clue",
    "interlocking",
    "jigsaw",
    "piece",
    "puzzle"
  ],
  "1f3ae": [
    "controller",
    "game",
    "video game"
  ],
  "1f3b2": [
    "dice",
    "die",
    "game"
  ],
  "1f3b0": [
    "game",
    "slot",
    "slot machine"
  ],
  "1f3b4": [
    "card",
    "flower",
    "flower playing cards",
    "game",
    "Japanese",
    "playing"
  ],
  "1f004": [
    "game",
    "mahjong",
    "mahjong red dragon",
    "red"
  ],
  "1f0cf": [
    "card",
    "game",
    "joker",
    "wildcard"
  ],
  "1f4f7": [
    "camera",
    "video"
  ],
  "1f3a8": [
    "art",
    "artist palette",
    "museum",
    "painting",
    "palette"
  ],
  "1f58c": [
    "paintbrush",
    "painting"
  ],
  "1f58d": [
    "crayon"
  ],
  "1faa1": [
    "embroidery",
    "needle",
    "sewing",
    "stitches",
    "sutures",
    "tailoring"
  ],
  "1f9f5": [
    "needle",
    "sewing",
    "spool",
    "string",
    "thread"
  ],
  "1f9f6": [
    "ball",
    "crochet",
    "knit",
    "yarn"
  ],
  "1f3b9": [
    "instrument",
    "keyboard",
    "music",
    "musical keyboard",
    "piano"
  ],
  "1f3b7": [
    "instrument",
    "music",
    "sax",
    "saxophone"
  ],
  "1f3ba": [
    "instrument",
    "music",
    "trumpet"
  ],
  "1f3b8": [
    "guitar",
    "instrument",
    "music"
  ],
  "1fa95": [
    "banjo",
    "music",
    "stringed"
  ],
  "1f3bb": [
    "instrument",
    "music",
    "violin"
  ],
  "1fa98": [
    "beat",
    "conga",
    "drum",
    "long drum",
    "rhythm"
  ],
  "1f941": [
    "drum",
    "drumsticks",
    "music"
  ],
  "1fa97": [
    "accordion",
    "concertina",
    "squeeze box"
  ],
  "1f3a4": [
    "karaoke",
    "mic",
    "microphone"
  ],
  "1f3a7": [
    "earbud",
    "headphone"
  ],
  "1f39a": [
    "level",
    "music",
    "slider"
  ],
  "1f39b": [
    "control",
    "knobs",
    "music"
  ],
  "1f399": [
    "mic",
    "microphone",
    "music",
    "studio"
  ],
  "1f4fb": [
    "radio",
    "video"
  ],
  "1f4fa": [
    "television",
    "tv",
    "video"
  ],
  "1f4fc": [
    "tape",
    "vhs",
    "video",
    "videocassette"
  ],
  "1f39e": [
    "cinema",
    "film",
    "frames",
    "movie"
  ],
  "1f3ac": [
    "clapper",
    "clapper board",
    "movie"
  ],
  "1f3ad": [
    "art",
    "mask",
    "performing",
    "performing arts",
    "theater",
    "theatre"
  ],
  "1f39f": [
    "admission",
    "admission tickets",
    "ticket"
  ],
  "1f4f1": [
    "cell",
    "mobile",
    "phone",
    "telephone"
  ],
  "260e": [
    "phone",
    "telephone"
  ],
  "1f4df": [
    "pager"
  ],
  "1f4e0": [
    "fax",
    "fax machine"
  ],
  "1f50c": [
    "electric",
    "electricity",
    "plug"
  ],
  "1f50b": [
    "battery"
  ],
  "1faab": [
    "electronic",
    "low battery",
    "low energy"
  ],
  "1f4be": [
    "computer",
    "disk",
    "floppy"
  ],
  "1f4bf": [
    "CD",
    "computer",
    "disk",
    "optical"
  ],
  "1f4bb": [
    "computer",
    "laptop",
    "pc",
    "personal"
  ],
  "1f5a8": [
    "computer",
    "printer"
  ],
  "1f4b8": [
    "banknote",
    "bill",
    "fly",
    "money",
    "money with wings",
    "wings"
  ],
  "1f4a1": [
    "bulb",
    "comic",
    "electric",
    "idea",
    "light"
  ],
  "1f6cb": [
    "couch",
    "couch and lamp",
    "hotel",
    "lamp"
  ],
  "1f9f9": [
    "broom",
    "cleaning",
    "sweeping",
    "witch"
  ],
  "1f9fc": [
    "bar",
    "bathing",
    "cleaning",
    "lather",
    "soap",
    "soapdish"
  ],
  "1fa92": [
    "razor",
    "sharp",
    "shave"
  ],
  "1faae": [
    "Afro",
    "comb",
    "hair",
    "pick"
  ],
  "1f9e6": [
    "socks",
    "stocking"
  ],
  "1f393": [
    "cap",
    "celebration",
    "clothing",
    "graduation",
    "hat"
  ],
  "1f451": [
    "clothing",
    "crown",
    "king",
    "queen"
  ],
  "1f48d": [
    "diamond",
    "ring"
  ],
  "1f48e": [
    "diamond",
    "gem",
    "gem stone",
    "jewel"
  ],
  "1f460": [
    "clothing",
    "heel",
    "high-heeled shoe",
    "shoe",
    "woman"
  ],
  "1f9ea": [
    "chemist",
    "chemistry",
    "experiment",
    "lab",
    "science",
    "test tube"
  ],
  "1f321": [
    "thermometer",
    "weather"
  ],
  "1fa7a": [
    "doctor",
    "heart",
    "medicine",
    "stethoscope"
  ],
  "1f9ec": [
    "biologist",
    "dna",
    "evolution",
    "gene",
    "genetics",
    "life"
  ],
  "1f52d": [
    "science",
    "telescope",
    "tool"
  ],
  "1f52c": [
    "microscope",
    "science",
    "tool"
  ],
  "1f9f2": [
    "attraction",
    "horseshoe",
    "magnet",
    "magnetic"
  ],
  "1f6e0": [
    "hammer",
    "hammer and wrench",
    "spanner",
    "tool",
    "wrench"
  ],
  "26cf": [
    "mining",
    "pick",
    "tool"
  ],
  "26d3": [
    "chain",
    "chains"
  ],
  "1f587": [
    "link",
    "linked paperclips",
    "paperclip"
  ],
  "1f4cf": [
    "ruler",
    "straight edge",
    "straight ruler"
  ],
  "1f5d1": [
    "wastebasket"
  ],
  "1f58a": [
    "ballpoint",
    "pen"
  ],
  "270f": [
    "pencil"
  ],
  "1f4da": [
    "book",
    "books"
  ],
  "1f5c3": [
    "box",
    "card",
    "file"
  ],
  "1f4ca": [
    "bar",
    "chart",
    "graph"
  ],
  "1f4c8": [
    "chart",
    "chart increasing",
    "graph",
    "growth",
    "trend",
    "upward"
  ],
  "1f4c9": [
    "chart",
    "chart decreasing",
    "down",
    "graph",
    "trend"
  ],
  "1f4f0": [
    "news",
    "newspaper",
    "paper"
  ],
  "231b": [
    "hourglass done",
    "sand",
    "timer"
  ],
  "23f3": [
    "hourglass",
    "hourglass not done",
    "sand",
    "timer"
  ],
  "23f0": [
    "alarm",
    "clock"
  ],
  "1f514": [
    "bell"
  ],
  "1f4e3": [
    "cheering",
    "megaphone"
  ],
  "1f50e": [
    "glass",
    "magnifying",
    "magnifying glass tilted right",
    "search",
    "tool"
  ],
  "1f52e": [
    "ball",
    "crystal",
    "fairy tale",
    "fantasy",
    "fortune",
    "tool"
  ],
  "1f9ff": [
    "bead",
    "charm",
    "evil-eye",
    "nazar",
    "nazar amulet",
    "talisman"
  ],
  "1faa4": [
    "bait",
    "mouse trap",
    "mousetrap",
    "snare",
    "trap"
  ],
  "1f5dd": [
    "clue",
    "key",
    "lock",
    "old"
  ],
  "1f512": [
    "closed",
    "locked"
  ],
  "264a": [
    "Gemini",
    "twins",
    "zodiac"
  ],
  "264b": [
    "Cancer",
    "crab",
    "zodiac"
  ],
  "264c": [
    "Leo",
    "lion",
    "zodiac"
  ],
  "264d": [
    "Virgo",
    "zodiac"
  ],
  "264e": [
    "balance",
    "justice",
    "Libra",
    "scales",
    "zodiac"
  ],
  "264f": [
    "Scorpio",
    "scorpion",
    "scorpius",
    "zodiac"
  ],
  "26ce": [
    "bearer",
    "Ophiuchus",
    "serpent",
    "snake",
    "zodiac"
  ],
  "1f198": [
    "help",
    "SOS",
    "SOS button"
  ],
  "1f4f4": [
    "cell",
    "mobile",
    "off",
    "phone",
    "telephone"
  ],
  "1f508": [
    "soft",
    "speaker low volume"
  ],
  "26a0": [
    "warning"
  ],
  "267b": [
    "recycle",
    "recycling symbol"
  ],
  "1f195": [
    "NEW",
    "NEW button"
  ],
  "1f193": [
    "FREE",
    "FREE button"
  ],
  "1f199": [
    "mark",
    "UP",
    "UP!",
    "UP! button"
  ],
  "1f197": [
    "OK",
    "OK button"
  ],
  "1f192": [
    "COOL",
    "COOL button"
  ],
  "1f6ae": [
    "litter",
    "litter bin",
    "litter in bin sign"
  ],
  "262e": [
    "peace",
    "peace symbol"
  ],
  "262f": [
    "religion",
    "tao",
    "taoist",
    "yang",
    "yin"
  ],
  "267e": [
    "forever",
    "infinity",
    "unbounded",
    "universal"
  ],
  "27b0": [
    "curl",
    "curly loop",
    "loop"
  ],
  "27bf": [
    "curl",
    "double",
    "double curly loop",
    "loop"
  ],
  "00a9": [
    "C",
    "copyright"
  ],
  "00ae": [
    "R",
    "registered"
  ],
  "1f5ef": [
    "angry",
    "balloon",
    "bubble",
    "mad",
    "right anger bubble"
  ],
  "1f4ac": [
    "balloon",
    "bubble",
    "comic",
    "dialog",
    "speech"
  ]
}