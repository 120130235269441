const UPDATE_STREAK = 'UPDATE_STREAK'

const state = {
  streak: 0
}

const actions = {
  updateStreak ({ commit }, val) {
    commit(UPDATE_STREAK, val)
  },
}

const mutations = {
  [UPDATE_STREAK] (state, val) {
    state.streak = val
  },
}

export default {
  state,
  actions,
  mutations
}