<template>
  <div class="game">
    <div class="animate__animated animate__fadeIn">
      <img
        class="game-image"
        v-if="game.result.length > 0"
        :src="game.result"
      />
      <Guesser @answer="answer" v-show="!finished" />
      <div v-show="finished && !won">Correct answer</div>
      <div v-if="finished && won" class="green">Good job!</div>
      <Answer v-show="finished" :answer="realAnswer" />
      <div v-show="finished && !won" class="red">Your guess:</div>
      <Answer v-show="finished && !won" :answer="yourAnswer" />
      <div class="finish-container">
        <Share v-show="finished && won" />
        <div
          v-show="showFinish"
          class="finish animate__animated animate__fadeInUp"
          id="end"
        >
          <div v-if="autoplay" class="auto-play-container">
            <div>Replay in {{ countAutoplay }}</div>
            <!-- <div>
            <span class="cancel" @click="changeAutoPlayValue" >Cancel auto-play</span>
          </div> -->
          </div>
          <div v-else>
            <div>Replay</div>
            <div>
              <img
                @click="playAgain"
                src="../static/img/Refresh.png"
                width="50px"
              />
            </div>
          </div>
          <div class="autoplay">
            Auto replay
            <ToggleButton
              @change="changeAutoPlayValue"
              :value="autoplay"
              :speed="100"
              color="#14AAB2"
              :width="65"
              :height="23"
            />
          </div>
        </div>
      </div>
    </div>
    <Confettis ref="confettis" />
  </div>
</template>

<script>
import bus from "../tools/bus";
import { ToggleButton } from "vue-js-toggle-button";
import Guesser from "./Guesser.vue";
import Answer from "./Answer.vue";
import games from "../static/data/games";
import Share from "./Share.vue";
import Confettis from "./Confettis.vue";

export default {
  data() {
    return {
      finished: false,
      won: false,
      showFinish: false,
      autoplay: false,
      countAutoplay: 0,
      autoplayInterval: null,
      game: {
        first: "",
        second: "",
        result: "",
      },
      realAnswer: {
        left: "",
        right: "",
      },
      yourAnswer: {
        left: "",
        right: "",
      },
    };
  },
  created() {
    this.playAgain();
    this.initAutoplay();
  },
  methods: {
    initAutoplay() {
      const localAutoplay = localStorage.getItem("autoplay");
      if (localAutoplay !== null) {
        this.autoplay = JSON.parse(localAutoplay);
      }
    },
    answer(payload) {
      const first = payload[0];
      // const first = this.game.first;
      // const first = this.game.second;

      const second = payload[1];
      // const second = this.game.first;
      // const second = this.game.second;
      this.won =
        (this.game.first === first && this.game.second === second) ||
        (this.game.first === second && this.game.second === first);

      var realAnswer = {
        left: this.game.first,
        right: this.game.second,
      };
      var realAnswerSwap = {
        left: this.game.second,
        right: this.game.first,
      };
      if (first === this.game.second || second === this.game.first)
        this.realAnswer = realAnswerSwap;
      else this.realAnswer = realAnswer;

      this.yourAnswer = {
        left: first,
        right: second,
      };

      if (this.won && this.$refs.confettis !== undefined)
        this.$refs.confettis.launchConfettis();

      this.finished = true;
      bus.$emit("finish", this.won);
      this.showFinish = true;
      if (this.autoplay) this.doAutoPlay();
      // this.scrollToEnd();
    },
    playAgain() {
      this.finished = false;
      this.showFinish = false;
      this.generateGame();
    },
    generateGame() {
      const random = this.getRandomItem(games);
      this.game = {
        first: random[0],
        second: random[1],
        result:
          "https://www.gstatic.com/android/keyboard/emojikitchen/" + random[2],
      };
      bus.$emit("restart");
    },
    getRandomIndex(array) {
      return Math.floor(Math.random() * array.length);
    },
    getRandomItem(array) {
      return array[this.getRandomIndex(array)];
    },
    scrollToEnd() {
      this.$scrollTo("#end", 1000);
    },
    doAutoPlay() {
      this.countAutoplay = 4;
      this.autoplayInterval = setInterval(() => {
        this.countAutoplay--;
      }, 1000);
    },
    onEndAutoplayTimer() {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
      this.playAgain();
    },
    changeAutoPlayValue() {
      if (this.autoplay) {
        if (this.autoplayInterval !== null) {
          clearInterval(this.autoplayInterval);
          this.autoplayInterval = null;
        }
      } else {
        this.doAutoPlay();
      }
      this.autoplay = !this.autoplay;
      localStorage.setItem("autoplay", this.autoplay);
    },
  },
  watch: {
    countAutoplay(value, __) {
      if (value === 0) this.onEndAutoplayTimer();
    },
  },
  components: {
    ToggleButton,
    Guesser,
    Answer,
    Share,
    Confettis,
  },
};
</script>

<style scoped>
.game {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  row-gap: 2rem;
  width: 100%;
  min-width: 100%;
  /* height: 100%; */
  flex: 1;
  justify-content: space-around;
  transition: all 0.2s ease;
}

.finish-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.finish {
  display: flex;
  flex-direction: column;
  font-size: 1.7rem;
  cursor: default;
  row-gap: 1rem;
}

.finish img {
  transition: transform 0.2s ease-in-out;
}

.finish img:hover {
  transform: rotate(360deg);
  cursor: pointer;
}

.button:main,
.button:focus {
  user-select: auto;
}

.button:disabled {
  cursor: auto;
}

.default {
  appearance: button;
  background-color: #1899d6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.default:after {
  background-clip: padding-box;
  background-color: #1cb0f6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.default:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.won {
  appearance: button;
  background-color: rgb(15, 156, 15);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.won:after {
  background-clip: padding-box;
  background-color: rgb(17, 176, 17);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.fail {
  background-color: rgb(197, 2, 2);
  appearance: button;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.fail:after {
  background-color: rgb(221, 0, 0);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.gray {
  background-color: gray;
  appearance: button;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: din-round, sans-serif;
  /* font-size: 15px; */
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  display: flex;

  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.gray:after {
  background-color: rgb(146, 146, 146);
  background-clip: padding-box;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

@keyframes pop {
  0% {
    transform: scaleX(0);
  }
  85% {
    transform: scaleX(1.1);
  }
  100% {
    transform: scaleX(1);
  }
}

.pop {
  animation: 0.2s ease pop;
}

.pop-delay {
  animation-delay: 0.25s;
}

.auto-play-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.auto-play-container .cancel {
  font-size: 1rem;
  color: rgb(56, 56, 212);
}

.auto-play-container .cancel:hover {
  cursor: pointer;
  font-weight: 1000;
}

.autoplay {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  text-align: center;
  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.game-image {
  width: 200px;
  transition: all ease 0.2s;
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 55px,
    rgba(0, 0, 0, 0.05) 0px -12px 30px, rgba(0, 0, 0, 0.02) 0px 4px 6px,
    rgba(0, 0, 0, 0.07) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.game-image:hover {
  width: 205px;
}

@media screen and (max-width: 450px) {
  .game-image {
    width: 50%;
  }
}
</style>