export default [
  [
    "1f5a4",
    "1f47e",
    "20230803/u1f47e/u1f47e_u1f5a4.png"
  ],
  [
    "1f9e1",
    "1f36c",
    "20230127/u1f36c/u1f36c_u1f9e1.png"
  ],
  [
    "1f600",
    "1f36c",
    "20230127/u1f36c/u1f36c_u1f600.png"
  ],
  [
    "1f9c6",
    "1f602",
    "20220815/u1f602/u1f602_u1f9c6.png"
  ],
  [
    "2696",
    "1f945",
    "20230418/u1f945/u1f945_u2696-ufe0f.png"
  ],
  [
    "1f5dd",
    "1f5d1",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u1f5dd-ufe0f.png"
  ],
  [
    "1f94d",
    "1f618",
    "20230301/u1f618/u1f618_u1f94d.png"
  ],
  [
    "1f68c",
    "1f493",
    "20220203/u1f493/u1f493_u1f68c.png"
  ],
  [
    "1f358",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f358.png"
  ],
  [
    "1f36b",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f36b.png"
  ],
  [
    "1f624",
    "1f9c0",
    "20201001/u1f624/u1f624_u1f9c0.png"
  ],
  [
    "1fa98",
    "1f97a",
    "20220815/u1f97a/u1f97a_u1fa98.png"
  ],
  [
    "1f37f",
    "1f498",
    "20220203/u1f498/u1f498_u1f37f.png"
  ],
  [
    "1fad3",
    "1f31f",
    "20220203/u1f31f/u1f31f_u1fad3.png"
  ],
  [
    "1f33d",
    "1fa84",
    "20220815/u1fa84/u1fa84_u1f33d.png"
  ],
  [
    "1f3e0",
    "1f614",
    "20220815/u1f614/u1f614_u1f3e0.png"
  ],
  [
    "1f9c0",
    "1f31e",
    "20230127/u1f31e/u1f31e_u1f9c0.png"
  ],
  [
    "1f47b",
    "1f4a9",
    "20201001/u1f4a9/u1f4a9_u1f47b.png"
  ],
  [
    "267e",
    "1f614",
    "20220815/u1f614/u1f614_u267e-ufe0f.png"
  ],
  [
    "1f386",
    "1f36b",
    "20230418/u1f386/u1f386_u1f36b.png"
  ],
  [
    "1f62e",
    "1f9c1",
    "20230301/u1f62e/u1f62e_u1f9c1.png"
  ],
  [
    "1f971",
    "1f92f",
    "20201001/u1f92f/u1f92f_u1f971.png"
  ],
  [
    "1f957",
    "1f60d",
    "20220203/u1f60d/u1f60d_u1f957.png"
  ],
  [
    "1f366",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f366.png"
  ],
  [
    "1f3d1",
    "1f386",
    "20230418/u1f386/u1f386_u1f3d1.png"
  ],
  [
    "1f341",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f341.png"
  ],
  [
    "1f33d",
    "1f493",
    "20220203/u1f493/u1f493_u1f33d.png"
  ],
  [
    "1f959",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f959.png"
  ],
  [
    "1f39b",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f39b-ufe0f.png"
  ],
  [
    "26be",
    "1f614",
    "20220815/u1f614/u1f614_u26be.png"
  ],
  [
    "1f3ac",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f3ac.png"
  ],
  [
    "1f61a",
    "1f410",
    "20210831/u1f410/u1f410_u1f61a.png"
  ],
  [
    "1f30a",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f30a.png"
  ],
  [
    "264a",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u264a.png"
  ],
  [
    "1f3bb",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f3bb.png"
  ],
  [
    "1fab4",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1fab4.png"
  ],
  [
    "1f4e0",
    "1f618",
    "20230418/u1f618/u1f618_u1f4e0.png"
  ],
  [
    "1f49d",
    "1f602",
    "20201001/u1f602/u1f602_u1f49d.png"
  ],
  [
    "1faa4",
    "1f3b0",
    "20230418/u1faa4/u1faa4_u1f3b0.png"
  ],
  [
    "1fab7",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1fab7.png"
  ],
  [
    "1fada",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1fada.png"
  ],
  [
    "1f94e",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f94e.png"
  ],
  [
    "1fada",
    "1f327",
    "20230418/u1f327-ufe0f/u1f327-ufe0f_u1fada.png"
  ],
  [
    "2693",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u2693.png"
  ],
  [
    "1f975",
    "1f49e",
    "20201001/u1f975/u1f975_u1f49e.png"
  ],
  [
    "1fad5",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1fad5.png"
  ],
  [
    "1f52e",
    "1f3c5",
    "20220506/u1f52e/u1f52e_u1f3c5.png"
  ],
  [
    "1f952",
    "1f386",
    "20230418/u1f386/u1f386_u1f952.png"
  ],
  [
    "1f61a",
    "1f31f",
    "20201001/u1f61a/u1f61a_u1f31f.png"
  ],
  [
    "1f426",
    "1f637",
    "20210831/u1f426/u1f426_u1f637.png"
  ],
  [
    "1f635",
    "1f614",
    "20201001/u1f614/u1f614_u1f635.png"
  ],
  [
    "1f52c",
    "1f945",
    "20230418/u1f945/u1f945_u1f52c.png"
  ],
  [
    "1f3ae",
    "1f47b",
    "20220815/u1f47b/u1f47b_u1f3ae.png"
  ],
  [
    "1f921",
    "1f9d0",
    "20201001/u1f9d0/u1f9d0_u1f921.png"
  ],
  [
    "1f33d",
    "1f945",
    "20230418/u1f945/u1f945_u1f33d.png"
  ],
  [
    "1fad4",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1fad4.png"
  ],
  [
    "1f4f0",
    "1fae2",
    "20211115/u1fae2/u1fae2_u1f4f0.png"
  ],
  [
    "2763",
    "1f613",
    "20201001/u1f613/u1f613_u2763-ufe0f.png"
  ],
  [
    "1f61f",
    "1f987",
    "20201001/u1f61f/u1f61f_u1f987.png"
  ],
  [
    "264e",
    "1f498",
    "20220203/u1f498/u1f498_u264e.png"
  ],
  [
    "1f381",
    "1f47f",
    "20211115/u1f381/u1f381_u1f47f.png"
  ],
  [
    "1f31f",
    "1f36a",
    "20230127/u1f31f/u1f31f_u1f36a.png"
  ],
  [
    "1f33b",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f33b.png"
  ],
  [
    "1f3c9",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f3c9.png"
  ],
  [
    "1f49b",
    "1f451",
    "20201001/u1f49b/u1f49b_u1f451.png"
  ],
  [
    "1f42f",
    "1f435",
    "20220110/u1f42f/u1f42f_u1f435.png"
  ],
  [
    "1fad5",
    "1f970",
    "20220203/u1f970/u1f970_u1fad5.png"
  ],
  [
    "1fa92",
    "1fa84",
    "20230418/u1fa84/u1fa84_u1fa92.png"
  ],
  [
    "1f4a1",
    "1f94c",
    "20230418/u1f4a1/u1f4a1_u1f94c.png"
  ],
  [
    "1f383",
    "1f607",
    "20201001/u1f607/u1f607_u1f383.png"
  ],
  [
    "1f3b2",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f3b2.png"
  ],
  [
    "1faae",
    "1f495",
    "20230418/u1f495/u1f495_u1faae.png"
  ],
  [
    "1f94d",
    "1f498",
    "20220203/u1f498/u1f498_u1f94d.png"
  ],
  [
    "1f47d",
    "1f4a9",
    "20201001/u1f4a9/u1f4a9_u1f47d.png"
  ],
  [
    "1f9e9",
    "1f970",
    "20220203/u1f970/u1f970_u1f9e9.png"
  ],
  [
    "1f96c",
    "1f945",
    "20230418/u1f945/u1f945_u1f96c.png"
  ],
  [
    "1f94e",
    "1f618",
    "20220203/u1f618/u1f618_u1f94e.png"
  ],
  [
    "1f630",
    "1f495",
    "20201001/u1f630/u1f630_u1f495.png"
  ],
  [
    "1f4fa",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f4fa.png"
  ],
  [
    "1f436",
    "1f9c1",
    "20211115/u1f436/u1f436_u1f9c1.png"
  ],
  [
    "1f419",
    "1f928",
    "20201001/u1f928/u1f928_u1f419.png"
  ],
  [
    "1faae",
    "1f493",
    "20230418/u1f493/u1f493_u1faae.png"
  ],
  [
    "1fad2",
    "1f96b",
    "20230418/u1f96b/u1f96b_u1fad2.png"
  ],
  [
    "264b",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u264b.png"
  ],
  [
    "1f49e",
    "2601",
    "20221107/u1f49e/u1f49e_u2601-ufe0f.png"
  ],
  [
    "1f4c8",
    "1f493",
    "20230418/u1f493/u1f493_u1f4c8.png"
  ],
  [
    "1f924",
    "1f410",
    "20210831/u1f410/u1f410_u1f924.png"
  ],
  [
    "1f300",
    "1f945",
    "20230418/u1f945/u1f945_u1f300.png"
  ],
  [
    "1f497",
    "1f499",
    "20201001/u1f499/u1f499_u1f497.png"
  ],
  [
    "1f4af",
    "1f48e",
    "20220815/u1f4af/u1f4af_u1f48e.png"
  ],
  [
    "1f60a",
    "1f62f",
    "20201001/u1f60a/u1f60a_u1f62f.png"
  ],
  [
    "1f96c",
    "1f327",
    "20230418/u1f327-ufe0f/u1f327-ufe0f_u1f96c.png"
  ],
  [
    "1f48d",
    "1f495",
    "20230418/u1f495/u1f495_u1f48d.png"
  ],
  [
    "2604",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u2604-ufe0f.png"
  ],
  [
    "1f490",
    "1f47f",
    "20201001/u1f47f/u1f47f_u1f490.png"
  ],
  [
    "1f36a",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f36a.png"
  ],
  [
    "1f4ab",
    "1f978",
    "20201001/u1f4ab/u1f4ab_u1f978.png"
  ],
  [
    "1f192",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f192.png"
  ],
  [
    "1f633",
    "1f9a5",
    "20201001/u1f633/u1f633_u1f9a5.png"
  ],
  [
    "26bd",
    "1f308",
    "20220406/u26bd/u26bd_u1f308.png"
  ],
  [
    "1fa95",
    "1f498",
    "20220203/u1f498/u1f498_u1fa95.png"
  ],
  [
    "1f4f4",
    "1f5d1",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u1f4f4.png"
  ],
  [
    "1f90d",
    "2665",
    "20201001/u1f90d/u1f90d_u2665-ufe0f.png"
  ],
  [
    "1f3b2",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f3b2.png"
  ],
  [
    "1f60f",
    "1fae3",
    "20211115/u1fae3/u1fae3_u1f60f.png"
  ],
  [
    "1f3b9",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f3b9.png"
  ],
  [
    "1f334",
    "1f31f",
    "20220506/u1f31f/u1f31f_u1f334.png"
  ],
  [
    "1f632",
    "1f42d",
    "20201001/u1f632/u1f632_u1f42d.png"
  ],
  [
    "1f9b7",
    "1f498",
    "20220203/u1f498/u1f498_u1f9b7.png"
  ],
  [
    "1f928",
    "1f431",
    "20230301/u1f928/u1f928_u1f431.png"
  ],
  [
    "1f621",
    "1f602",
    "20201001/u1f602/u1f602_u1f621.png"
  ],
  [
    "1f3de",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1f3de-ufe0f.png"
  ],
  [
    "1f648",
    "1f3a7",
    "20230127/u1f3a7/u1f3a7_u1f648.png"
  ],
  [
    "264b",
    "1faa4",
    "20230418/u1faa4/u1faa4_u264b.png"
  ],
  [
    "1f3f9",
    "1f602",
    "20220815/u1f602/u1f602_u1f3f9.png"
  ],
  [
    "1f332",
    "1f606",
    "20201001/u1f606/u1f606_u1f332.png"
  ],
  [
    "2763",
    "1fa7a",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1fa7a.png"
  ],
  [
    "267b",
    "1f495",
    "20220203/u1f495/u1f495_u267b-ufe0f.png"
  ],
  [
    "1f967",
    "1f60d",
    "20230418/u1f60d/u1f60d_u1f967.png"
  ],
  [
    "1f970",
    "1f41a",
    "20230301/u1f970/u1f970_u1f41a.png"
  ],
  [
    "1f3c0",
    "1f626",
    "20230126/u1f3c0/u1f3c0_u1f626.png"
  ],
  [
    "1f3df",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f3df-ufe0f.png"
  ],
  [
    "1f61a",
    "1f31f",
    "20201001/u1f61a/u1f61a_u1f31f.png"
  ],
  [
    "2615",
    "1f9a5",
    "20201001/u1f9a5/u1f9a5_u2615.png"
  ],
  [
    "1f3aa",
    "1f970",
    "20220203/u1f970/u1f970_u1f3aa.png"
  ],
  [
    "1f4fb",
    "1f60d",
    "20230418/u1f60d/u1f60d_u1f4fb.png"
  ],
  [
    "1f37f",
    "1f945",
    "20230418/u1f945/u1f945_u1f37f.png"
  ],
  [
    "1f33c",
    "2763",
    "20201001/u2763-ufe0f/u2763-ufe0f_u1f33c.png"
  ],
  [
    "1f49b",
    "1f352",
    "20220406/u1f352/u1f352_u1f49b.png"
  ],
  [
    "1f9c2",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f9c2.png"
  ],
  [
    "1f697",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1f697.png"
  ],
  [
    "1f62b",
    "1f641",
    "20201001/u1f641/u1f641_u1f62b.png"
  ],
  [
    "1f5c3",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f5c3-ufe0f.png"
  ],
  [
    "1fa90",
    "1f38a",
    "20230127/u1f38a/u1f38a_u1fa90.png"
  ],
  [
    "1f577",
    "1f62a",
    "20201001/u1f62a/u1f62a_u1f577-ufe0f.png"
  ],
  [
    "260e",
    "1f970",
    "20220203/u1f970/u1f970_u260e-ufe0f.png"
  ],
  [
    "1f3a2",
    "1f618",
    "20220506/u1f618/u1f618_u1f3a2.png"
  ],
  [
    "1f9a9",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f9a9.png"
  ],
  [
    "1f988",
    "1f92c",
    "20230418/u1f988/u1f988_u1f92c.png"
  ],
  [
    "1f633",
    "1f497",
    "20201001/u1f633/u1f633_u1f497.png"
  ],
  [
    "2763",
    "1f192",
    "20220203/u2763-ufe0f/u2763-ufe0f_u1f192.png"
  ],
  [
    "260e",
    "1f97a",
    "20220815/u1f97a/u1f97a_u260e-ufe0f.png"
  ],
  [
    "1f3c9",
    "1f970",
    "20220203/u1f970/u1f970_u1f3c9.png"
  ],
  [
    "2639",
    "1f382",
    "20201001/u2639-ufe0f/u2639-ufe0f_u1f382.png"
  ],
  [
    "1f96c",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f96c.png"
  ],
  [
    "1f338",
    "1f410",
    "20210831/u1f410/u1f410_u1f338.png"
  ],
  [
    "1f95e",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f95e.png"
  ],
  [
    "1f94d",
    "1f31f",
    "20230421/u1f31f/u1f31f_u1f94d.png"
  ],
  [
    "1fae7",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1fae7.png"
  ],
  [
    "1f4fc",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f4fc.png"
  ],
  [
    "1f42d",
    "1f619",
    "20201001/u1f619/u1f619_u1f42d.png"
  ],
  [
    "1f971",
    "1f49d",
    "20201001/u1f971/u1f971_u1f49d.png"
  ],
  [
    "1f910",
    "1f601",
    "20201001/u1f601/u1f601_u1f910.png"
  ],
  [
    "1f436",
    "1f9a5",
    "20211115/u1f436/u1f436_u1f9a5.png"
  ],
  [
    "1f356",
    "1f495",
    "20220203/u1f495/u1f495_u1f356.png"
  ],
  [
    "1f496",
    "1f979",
    "20211115/u1f979/u1f979_u1f496.png"
  ],
  [
    "1f35f",
    "1f602",
    "20220815/u1f602/u1f602_u1f35f.png"
  ],
  [
    "1f494",
    "1f30d",
    "20201001/u1f494/u1f494_u1f30d.png"
  ],
  [
    "1fa90",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1fa90.png"
  ],
  [
    "1fad5",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1fad5.png"
  ],
  [
    "1f31e",
    "1f438",
    "20230803/u1f438/u1f438_u1f31e.png"
  ],
  [
    "1f947",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f947.png"
  ],
  [
    "1f47b",
    "1f58c",
    "20220815/u1f47b/u1f47b_u1f58c-ufe0f.png"
  ],
  [
    "1f376",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1f376.png"
  ],
  [
    "1f941",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f941.png"
  ],
  [
    "1f913",
    "1f47f",
    "20201001/u1f47f/u1f47f_u1f913.png"
  ],
  [
    "1f34a",
    "1f62f",
    "20211115/u1f34a/u1f34a_u1f62f.png"
  ],
  [
    "1fad0",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1fad0.png"
  ],
  [
    "1f607",
    "1f426",
    "20210831/u1f426/u1f426_u1f607.png"
  ],
  [
    "1f615",
    "1f971",
    "20201001/u1f615/u1f615_u1f971.png"
  ],
  [
    "2693",
    "1f60e",
    "20220815/u1f60e/u1f60e_u2693.png"
  ],
  [
    "1f9ea",
    "1f38a",
    "20230418/u1f38a/u1f38a_u1f9ea.png"
  ],
  [
    "1f198",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f198.png"
  ],
  [
    "1f3ac",
    "1f31f",
    "20220203/u1f31f/u1f31f_u1f3ac.png"
  ],
  [
    "1f37f",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f37f.png"
  ],
  [
    "1f32c",
    "1f493",
    "20230216/u1f493/u1f493_u1f32c-ufe0f.png"
  ],
  [
    "1fae6",
    "1fa84",
    "20220203/u1fae6/u1fae6_u1fa84.png"
  ],
  [
    "1f430",
    "1f611",
    "20230301/u1f611/u1f611_u1f430.png"
  ],
  [
    "1f3de",
    "1f60d",
    "20230418/u1f60d/u1f60d_u1f3de-ufe0f.png"
  ],
  [
    "1f193",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f193.png"
  ],
  [
    "1f4cf",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1f4cf.png"
  ],
  [
    "1f92a",
    "1f612",
    "20201001/u1f92a/u1f92a_u1f612.png"
  ],
  [
    "1f4a5",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f4a5.png"
  ],
  [
    "1f321",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f321-ufe0f.png"
  ],
  [
    "1f35f",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1f35f.png"
  ],
  [
    "1fae0",
    "1faa8",
    "20220406/u1faa8/u1faa8_u1fae0.png"
  ],
  [
    "1f426",
    "1f627",
    "20210831/u1f426/u1f426_u1f627.png"
  ],
  [
    "1f399",
    "1f47b",
    "20220815/u1f47b/u1f47b_u1f399-ufe0f.png"
  ],
  [
    "1fac2",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1fac2.png"
  ],
  [
    "1f999",
    "1f47b",
    "20230216/u1f47b/u1f47b_u1f999.png"
  ],
  [
    "2796",
    "1f48b",
    "20220815/u1f48b/u1f48b_u2796.png"
  ],
  [
    "1f43b",
    "1f60f",
    "20210831/u1f43b/u1f43b_u1f60f.png"
  ],
  [
    "1f33d",
    "1fa84",
    "20220815/u1fa84/u1fa84_u1f33d.png"
  ],
  [
    "2660",
    "1f47b",
    "20220815/u1f47b/u1f47b_u2660-ufe0f.png"
  ],
  [
    "1f3ba",
    "1f495",
    "20220203/u1f495/u1f495_u1f3ba.png"
  ],
  [
    "2702",
    "1faa4",
    "20230418/u1faa4/u1faa4_u2702-ufe0f.png"
  ],
  [
    "1f99d",
    "1f601",
    "20211115/u1f99d/u1f99d_u1f601.png"
  ],
  [
    "1f4f7",
    "1f498",
    "20220203/u1f498/u1f498_u1f4f7.png"
  ],
  [
    "1f5ef",
    "1f350",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f350.png"
  ],
  [
    "1f345",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u1f345.png"
  ],
  [
    "1f43a",
    "1f44d",
    "20221101/u1f44d/u1f44d_u1f43a.png"
  ],
  [
    "1f3ac",
    "1f493",
    "20220203/u1f493/u1f493_u1f3ac.png"
  ],
  [
    "1f49e",
    "1f497",
    "20201001/u1f497/u1f497_u1f49e.png"
  ],
  [
    "1f913",
    "1f623",
    "20230301/u1f623/u1f623_u1f913.png"
  ],
  [
    "1f3ad",
    "1f945",
    "20230418/u1f945/u1f945_u1f3ad.png"
  ],
  [
    "1f379",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1f379.png"
  ],
  [
    "1f3f5",
    "1f52e",
    "20220506/u1f52e/u1f52e_u1f3f5-ufe0f.png"
  ],
  [
    "1f4fb",
    "1f386",
    "20230418/u1f386/u1f386_u1f4fb.png"
  ],
  [
    "1f959",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f959.png"
  ],
  [
    "1f4df",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1f4df.png"
  ],
  [
    "1f9e6",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f9e6.png"
  ],
  [
    "1f3e0",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f3e0.png"
  ],
  [
    "1f5d1",
    "2651",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u2651.png"
  ],
  [
    "2763",
    "1f47b",
    "20201001/u1f47b/u1f47b_u2763-ufe0f.png"
  ],
  [
    "1f43e",
    "1f386",
    "20230418/u1f386/u1f386_u1f43e.png"
  ],
  [
    "1f9fc",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f9fc.png"
  ],
  [
    "1f633",
    "26c4",
    "20201001/u1f633/u1f633_u26c4.png"
  ],
  [
    "1f436",
    "1f344",
    "20220406/u1f344/u1f344_u1f436.png"
  ],
  [
    "1f34c",
    "1f4af",
    "20211115/u1f34c/u1f34c_u1f4af.png"
  ],
  [
    "1f493",
    "1f9c1",
    "20230216/u1f493/u1f493_u1f9c1.png"
  ],
  [
    "1f623",
    "1f609",
    "20201001/u1f609/u1f609_u1f623.png"
  ],
  [
    "1f3bf",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f3bf.png"
  ],
  [
    "1f966",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f966.png"
  ],
  [
    "2764",
    "1f498",
    "20201001/u2764-ufe0f/u2764-ufe0f_u1f498.png"
  ],
  [
    "1f605",
    "1f490",
    "20230301/u1f605/u1f605_u1f490.png"
  ],
  [
    "1f911",
    "2763",
    "20201001/u1f911/u1f911_u2763-ufe0f.png"
  ],
  [
    "1f359",
    "1f618",
    "20220203/u1f618/u1f618_u1f359.png"
  ],
  [
    "2602",
    "1f618",
    "20230301/u1f618/u1f618_u2602-ufe0f.png"
  ],
  [
    "1f994",
    "1f619",
    "20201001/u1f619/u1f619_u1f994.png"
  ],
  [
    "1f33a",
    "1f970",
    "20220203/u1f970/u1f970_u1f33a.png"
  ],
  [
    "1f573",
    "1f48c",
    "20201001/u1f48c/u1f48c_u1f573-ufe0f.png"
  ],
  [
    "1f916",
    "1f621",
    "20201001/u1f621/u1f621_u1f916.png"
  ],
  [
    "1f607",
    "1f628",
    "20201001/u1f628/u1f628_u1f607.png"
  ],
  [
    "2615",
    "1f36c",
    "20221101/u1f36c/u1f36c_u2615.png"
  ],
  [
    "2653",
    "1f970",
    "20220203/u1f970/u1f970_u2653.png"
  ],
  [
    "1f927",
    "1f994",
    "20230301/u1f927/u1f927_u1f994.png"
  ],
  [
    "1f9f2",
    "1f381",
    "20230426/u1f381/u1f381_u1f9f2.png"
  ],
  [
    "260e",
    "1f30a",
    "20230418/u1f30a/u1f30a_u260e-ufe0f.png"
  ],
  [
    "1f3ad",
    "1f386",
    "20230418/u1f386/u1f386_u1f3ad.png"
  ],
  [
    "1f96a",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f96a.png"
  ],
  [
    "1f94e",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f94e.png"
  ],
  [
    "1f386",
    "1f3b2",
    "20230418/u1f386/u1f386_u1f3b2.png"
  ],
  [
    "2712",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u2712-ufe0f.png"
  ],
  [
    "1f39a",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1f39a-ufe0f.png"
  ],
  [
    "2665",
    "1f329",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f329-ufe0f.png"
  ],
  [
    "1f43b",
    "1f60b",
    "20210831/u1f43b/u1f43b_u1f60b.png"
  ],
  [
    "1f927",
    "1f426",
    "20210831/u1f426/u1f426_u1f927.png"
  ],
  [
    "1f92a",
    "1f910",
    "20201001/u1f92a/u1f92a_u1f910.png"
  ],
  [
    "1f955",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f955.png"
  ],
  [
    "1f375",
    "1f498",
    "20220203/u1f498/u1f498_u1f375.png"
  ],
  [
    "1f3b9",
    "1f937",
    "20220815/u1f937/u1f937_u1f3b9.png"
  ],
  [
    "1f9b7",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f9b7.png"
  ],
  [
    "26f3",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u26f3.png"
  ],
  [
    "1f496",
    "2615",
    "20201001/u1f496/u1f496_u2615.png"
  ],
  [
    "1f9a0",
    "1f642",
    "20201001/u1f642/u1f642_u1f9a0.png"
  ],
  [
    "1f34a",
    "1f307",
    "20211115/u1f34a/u1f34a_u1f307.png"
  ],
  [
    "1f947",
    "1f937",
    "20220815/u1f937/u1f937_u1f947.png"
  ],
  [
    "1f92b",
    "1f41d",
    "20201001/u1f92b/u1f92b_u1f41d.png"
  ],
  [
    "1f9c6",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f9c6.png"
  ],
  [
    "1f9f6",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f9f6.png"
  ],
  [
    "1f512",
    "2665",
    "20230301/u2665-ufe0f/u2665-ufe0f_u1f512.png"
  ],
  [
    "26c5",
    "1f602",
    "20220506/u1f602/u1f602_u26c5.png"
  ],
  [
    "1f48b",
    "1f336",
    "20201001/u1f48b/u1f48b_u1f336-ufe0f.png"
  ],
  [
    "1f61b",
    "1f914",
    "20201001/u1f61b/u1f61b_u1f914.png"
  ],
  [
    "1f962",
    "1f5d1",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u1f962.png"
  ],
  [
    "1f327",
    "1f93f",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f93f.png"
  ],
  [
    "1f965",
    "1f618",
    "20220203/u1f618/u1f618_u1f965.png"
  ],
  [
    "1f31d",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f31d.png"
  ],
  [
    "1f58c",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f58c-ufe0f.png"
  ],
  [
    "1f971",
    "1f4af",
    "20220815/u1f971/u1f971_u1f4af.png"
  ],
  [
    "2653",
    "1f618",
    "20220203/u1f618/u1f618_u2653.png"
  ],
  [
    "1f9c0",
    "1f92c",
    "20230301/u1f92c/u1f92c_u1f9c0.png"
  ],
  [
    "1f3bf",
    "1f618",
    "20230301/u1f618/u1f618_u1f3bf.png"
  ],
  [
    "1f94c",
    "1f97a",
    "20220815/u1f97a/u1f97a_u1f94c.png"
  ],
  [
    "1f4f1",
    "1f381",
    "20230127/u1f381/u1f381_u1f4f1.png"
  ],
  [
    "00ae",
    "1f493",
    "20220203/u1f493/u1f493_u00ae-ufe0f.png"
  ],
  [
    "26bd",
    "1f49f",
    "20220406/u26bd/u26bd_u1f49f.png"
  ],
  [
    "1f33a",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f33a.png"
  ],
  [
    "1f3d2",
    "1f618",
    "20230301/u1f618/u1f618_u1f3d2.png"
  ],
  [
    "1fae2",
    "1f920",
    "20211115/u1fae2/u1fae2_u1f920.png"
  ],
  [
    "1fad5",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1fad5.png"
  ],
  [
    "1f604",
    "1f912",
    "20201001/u1f604/u1f604_u1f912.png"
  ],
  [
    "1f43e",
    "1f47b",
    "20220815/u1f47b/u1f47b_u1f43e.png"
  ],
  [
    "1f928",
    "1f573",
    "20201001/u1f928/u1f928_u1f573-ufe0f.png"
  ],
  [
    "1f4e3",
    "1f493",
    "20220203/u1f493/u1f493_u1f4e3.png"
  ],
  [
    "1f343",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1f343.png"
  ],
  [
    "1f951",
    "1f923",
    "20201001/u1f923/u1f923_u1f951.png"
  ],
  [
    "1f912",
    "1f31b",
    "20201001/u1f912/u1f912_u1f31b.png"
  ],
  [
    "1f6f8",
    "1faae",
    "20230418/u1f6f8/u1f6f8_u1faae.png"
  ],
  [
    "1f37c",
    "1fa84",
    "20220506/u1fa84/u1fa84_u1f37c.png"
  ],
  [
    "1f41e",
    "1f498",
    "20220506/u1f498/u1f498_u1f41e.png"
  ],
  [
    "1f6f7",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f6f7.png"
  ],
  [
    "1f47e",
    "2615",
    "20230803/u1f47e/u1f47e_u2615.png"
  ],
  [
    "1f497",
    "1f625",
    "20201001/u1f625/u1f625_u1f497.png"
  ],
  [
    "1f3b0",
    "1f96a",
    "20230126/u1f3b0/u1f3b0_u1f96a.png"
  ],
  [
    "1faa4",
    "1f950",
    "20230418/u1faa4/u1faa4_u1f950.png"
  ],
  [
    "1f62a",
    "1f438",
    "20230803/u1f438/u1f438_u1f62a.png"
  ],
  [
    "1f953",
    "1f498",
    "20230418/u1f498/u1f498_u1f953.png"
  ],
  [
    "1f399",
    "1f498",
    "20220203/u1f498/u1f498_u1f399-ufe0f.png"
  ],
  [
    "1f440",
    "1f47e",
    "20230803/u1f47e/u1f47e_u1f440.png"
  ],
  [
    "1fada",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1fada.png"
  ],
  [
    "1f31c",
    "2615",
    "20230127/u1f31c/u1f31c_u2615.png"
  ],
  [
    "1f915",
    "1f61c",
    "20201001/u1f61c/u1f61c_u1f915.png"
  ],
  [
    "1f3bf",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f3bf.png"
  ],
  [
    "1f914",
    "2601",
    "20221107/u1f914/u1f914_u2601-ufe0f.png"
  ],
  [
    "1f610",
    "1f609",
    "20201001/u1f609/u1f609_u1f610.png"
  ],
  [
    "1f9b4",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f9b4.png"
  ],
  [
    "1f969",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f969.png"
  ],
  [
    "27bf",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u27bf.png"
  ],
  [
    "1f32f",
    "1f381",
    "20220506/u1f381/u1f381_u1f32f.png"
  ],
  [
    "1f341",
    "1f498",
    "20220815/u1f498/u1f498_u1f341.png"
  ],
  [
    "1f338",
    "2601",
    "20221107/u1f338/u1f338_u2601-ufe0f.png"
  ],
  [
    "1f928",
    "1fab5",
    "20211115/u1fab5/u1fab5_u1f928.png"
  ],
  [
    "1f35e",
    "1f923",
    "20230127/u1f35e/u1f35e_u1f923.png"
  ],
  [
    "1f4da",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f4da.png"
  ],
  [
    "1f3b3",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1f3b3.png"
  ],
  [
    "1f4bf",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f4bf.png"
  ],
  [
    "1f493",
    "1f90d",
    "20230301/u1f90d/u1f90d_u1f493.png"
  ],
  [
    "1f410",
    "1f635",
    "20210831/u1f410/u1f410_u1f635.png"
  ],
  [
    "1f300",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f300.png"
  ],
  [
    "1f321",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1f321-ufe0f.png"
  ],
  [
    "1f35d",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f35d.png"
  ],
  [
    "2665",
    "1f321",
    "20230418/u2665-ufe0f/u2665-ufe0f_u1f321-ufe0f.png"
  ],
  [
    "1f987",
    "1f34d",
    "20201001/u1f987/u1f987_u1f34d.png"
  ],
  [
    "2648",
    "1f38a",
    "20220815/u1f38a/u1f38a_u2648.png"
  ],
  [
    "1f437",
    "1f603",
    "20230301/u1f603/u1f603_u1f437.png"
  ],
  [
    "1f4da",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f4da.png"
  ],
  [
    "1f60e",
    "1f3be",
    "20220815/u1f60e/u1f60e_u1f3be.png"
  ],
  [
    "1f62a",
    "1f42e",
    "20230803/u1f42e/u1f42e_u1f62a.png"
  ],
  [
    "1f335",
    "1f912",
    "20201001/u1f912/u1f912_u1f335.png"
  ],
  [
    "1f58a",
    "1f614",
    "20220815/u1f614/u1f614_u1f58a-ufe0f.png"
  ],
  [
    "1f3bf",
    "1f937",
    "20220815/u1f937/u1f937_u1f3bf.png"
  ],
  [
    "1f36b",
    "1f498",
    "20220203/u1f498/u1f498_u1f36b.png"
  ],
  [
    "1f4f0",
    "1f633",
    "20230301/u1f633/u1f633_u1f4f0.png"
  ],
  [
    "1fa76",
    "2764",
    "20221101/u1fa76/u1fa76_u2764-ufe0f.png"
  ],
  [
    "23f3",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u23f3.png"
  ],
  [
    "1f6fc",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f6fc.png"
  ],
  [
    "2602",
    "1f602",
    "20220815/u1f602/u1f602_u2602-ufe0f.png"
  ],
  [
    "1f3d3",
    "1f5d1",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u1f3d3.png"
  ],
  [
    "1f958",
    "1f970",
    "20220203/u1f970/u1f970_u1f958.png"
  ],
  [
    "1f307",
    "1f308",
    "20230127/u1f307/u1f307_u1f308.png"
  ],
  [
    "1f941",
    "1f493",
    "20220203/u1f493/u1f493_u1f941.png"
  ],
  [
    "1f9c6",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f9c6.png"
  ],
  [
    "1f920",
    "1fae3",
    "20211115/u1fae3/u1fae3_u1f920.png"
  ],
  [
    "1f981",
    "1f633",
    "20201001/u1f633/u1f633_u1f981.png"
  ],
  [
    "1f32f",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f32f.png"
  ],
  [
    "1f338",
    "1f917",
    "20230127/u1f338/u1f338_u1f917.png"
  ],
  [
    "1fae1",
    "1f604",
    "20211115/u1fae1/u1fae1_u1f604.png"
  ],
  [
    "1f99e",
    "1f60d",
    "20220506/u1f60d/u1f60d_u1f99e.png"
  ],
  [
    "1f427",
    "1f38a",
    "20211115/u1f427/u1f427_u1f38a.png"
  ],
  [
    "1f3b8",
    "1f602",
    "20220815/u1f602/u1f602_u1f3b8.png"
  ],
  [
    "1f35d",
    "1fa84",
    "20220815/u1fa84/u1fa84_u1f35d.png"
  ],
  [
    "1f35b",
    "1f31f",
    "20220203/u1f31f/u1f31f_u1f35b.png"
  ],
  [
    "1f5a8",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f5a8-ufe0f.png"
  ],
  [
    "1f961",
    "1f327",
    "20230418/u1f327-ufe0f/u1f327-ufe0f_u1f961.png"
  ],
  [
    "1f379",
    "1f602",
    "20230301/u1f602/u1f602_u1f379.png"
  ],
  [
    "00ae",
    "1f495",
    "20220203/u1f495/u1f495_u00ae-ufe0f.png"
  ],
  [
    "1f33c",
    "1f984",
    "20210831/u1f984/u1f984_u1f33c.png"
  ],
  [
    "1f92f",
    "1f973",
    "20230301/u1f92f/u1f92f_u1f973.png"
  ],
  [
    "1f308",
    "1f62b",
    "20230301/u1f62b/u1f62b_u1f308.png"
  ],
  [
    "1f9cb",
    "1f937",
    "20220815/u1f937/u1f937_u1f9cb.png"
  ],
  [
    "1f644",
    "1f62c",
    "20201001/u1f644/u1f644_u1f62c.png"
  ],
  [
    "1f601",
    "1f606",
    "20201001/u1f601/u1f601_u1f606.png"
  ],
  [
    "1f602",
    "267e",
    "20220815/u1f602/u1f602_u267e-ufe0f.png"
  ],
  [
    "1f4c9",
    "1f60e",
    "20230418/u1f60e/u1f60e_u1f4c9.png"
  ],
  [
    "270f",
    "1f97a",
    "20220815/u1f97a/u1f97a_u270f-ufe0f.png"
  ],
  [
    "1f911",
    "2615",
    "20230301/u1f911/u1f911_u2615.png"
  ],
  [
    "1f300",
    "1f498",
    "20220203/u1f498/u1f498_u1f300.png"
  ],
  [
    "1fae3",
    "1f497",
    "20211115/u1fae3/u1fae3_u1f497.png"
  ],
  [
    "1f379",
    "1f47b",
    "20230216/u1f47b/u1f47b_u1f379.png"
  ],
  [
    "26cf",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u26cf-ufe0f.png"
  ],
  [
    "23f3",
    "1f970",
    "20230418/u1f970/u1f970_u23f3.png"
  ],
  [
    "1f95e",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f95e.png"
  ],
  [
    "263a",
    "1f971",
    "20201001/u263a-ufe0f/u263a-ufe0f_u1f971.png"
  ],
  [
    "1f9d0",
    "1f636",
    "20201001/u1f9d0/u1f9d0_u1f636.png"
  ],
  [
    "1f625",
    "1f34a",
    "20211115/u1f34a/u1f34a_u1f625.png"
  ],
  [
    "1f626",
    "1f3c6",
    "20211115/u1f3c6/u1f3c6_u1f626.png"
  ],
  [
    "1f43c",
    "1f332",
    "20201001/u1f43c/u1f43c_u1f332.png"
  ],
  [
    "1f42d",
    "1f61b",
    "20201001/u1f61b/u1f61b_u1f42d.png"
  ],
  [
    "1f911",
    "1f988",
    "20230418/u1f988/u1f988_u1f911.png"
  ],
  [
    "1f9fc",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f9fc.png"
  ],
  [
    "1f35e",
    "1f633",
    "20230127/u1f35e/u1f35e_u1f633.png"
  ],
  [
    "1f4ca",
    "1f5d1",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u1f4ca.png"
  ],
  [
    "1f948",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u1f948.png"
  ],
  [
    "1f4df",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f4df.png"
  ],
  [
    "1f9ff",
    "1f31f",
    "20220506/u1f31f/u1f31f_u1f9ff.png"
  ],
  [
    "1f927",
    "1f47f",
    "20201001/u1f927/u1f927_u1f47f.png"
  ],
  [
    "1f50c",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1f50c.png"
  ],
  [
    "1f967",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f967.png"
  ],
  [
    "1f93f",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f93f.png"
  ],
  [
    "1f33d",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f33d.png"
  ],
  [
    "1f4bb",
    "1f495",
    "20230418/u1f495/u1f495_u1f4bb.png"
  ],
  [
    "1f35f",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f35f.png"
  ],
  [
    "1f3c0",
    "1f47f",
    "20230126/u1f3c0/u1f3c0_u1f47f.png"
  ],
  [
    "1f3d1",
    "1f495",
    "20220203/u1f495/u1f495_u1f3d1.png"
  ],
  [
    "1fad8",
    "1f937",
    "20220815/u1f937/u1f937_u1fad8.png"
  ],
  [
    "1f437",
    "1f60a",
    "20230301/u1f60a/u1f60a_u1f437.png"
  ],
  [
    "1f635",
    "1f627",
    "20201001/u1f627/u1f627_u1f635.png"
  ],
  [
    "1fa92",
    "1f498",
    "20230418/u1f498/u1f498_u1fa92.png"
  ],
  [
    "1f6d1",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f6d1.png"
  ],
  [
    "1f90d",
    "1f496",
    "20201001/u1f90d/u1f90d_u1f496.png"
  ],
  [
    "1fae2",
    "1f92e",
    "20211115/u1fae2/u1fae2_u1f92e.png"
  ],
  [
    "1fae1",
    "1f916",
    "20211115/u1fae1/u1fae1_u1f916.png"
  ],
  [
    "2696",
    "1f970",
    "20220203/u1f970/u1f970_u2696-ufe0f.png"
  ],
  [
    "1f41e",
    "1f970",
    "20230301/u1f970/u1f970_u1f41e.png"
  ],
  [
    "1f635",
    "1f337",
    "20230301/u1f635/u1f635_u1f337.png"
  ],
  [
    "1f3b1",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f3b1.png"
  ],
  [
    "1f99d",
    "1f47d",
    "20211115/u1f99d/u1f99d_u1f47d.png"
  ],
  [
    "1f340",
    "1f937",
    "20220815/u1f937/u1f937_u1f340.png"
  ],
  [
    "1f94b",
    "1f381",
    "20220506/u1f381/u1f381_u1f94b.png"
  ],
  [
    "1f3a1",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f3a1.png"
  ],
  [
    "1f969",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f969.png"
  ],
  [
    "1f4e3",
    "1f495",
    "20220203/u1f495/u1f495_u1f4e3.png"
  ],
  [
    "1f373",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f373.png"
  ],
  [
    "1f99d",
    "1f431",
    "20211115/u1f99d/u1f99d_u1f431.png"
  ],
  [
    "1f321",
    "1f970",
    "20230418/u1f970/u1f970_u1f321-ufe0f.png"
  ],
  [
    "1f997",
    "1f38a",
    "20220506/u1f38a/u1f38a_u1f997.png"
  ],
  [
    "1f331",
    "1f618",
    "20230301/u1f618/u1f618_u1f331.png"
  ],
  [
    "1f333",
    "1f937",
    "20220815/u1f937/u1f937_u1f333.png"
  ],
  [
    "1f438",
    "1f47e",
    "20230803/u1f47e/u1f47e_u1f438.png"
  ],
  [
    "1fad0",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1fad0.png"
  ],
  [
    "1f617",
    "1f643",
    "20201001/u1f617/u1f617_u1f643.png"
  ],
  [
    "1f321",
    "1f327",
    "20230418/u1f327-ufe0f/u1f327-ufe0f_u1f321-ufe0f.png"
  ],
  [
    "1f97a",
    "1f300",
    "20220815/u1f97a/u1f97a_u1f300.png"
  ],
  [
    "1f618",
    "1f621",
    "20201001/u1f618/u1f618_u1f621.png"
  ],
  [
    "1f4cf",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f4cf.png"
  ],
  [
    "1f929",
    "1f60e",
    "20201001/u1f929/u1f929_u1f60e.png"
  ],
  [
    "1f3b7",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f3b7.png"
  ],
  [
    "1f308",
    "1f339",
    "20230127/u1f339/u1f339_u1f308.png"
  ],
  [
    "1f60c",
    "1f338",
    "20230127/u1f338/u1f338_u1f60c.png"
  ],
  [
    "1f912",
    "1f61d",
    "20201001/u1f61d/u1f61d_u1f912.png"
  ],
  [
    "1f499",
    "1f451",
    "20201001/u1f499/u1f499_u1f451.png"
  ],
  [
    "1f3b1",
    "1f60d",
    "20220203/u1f60d/u1f60d_u1f3b1.png"
  ],
  [
    "1f62a",
    "1f625",
    "20201001/u1f625/u1f625_u1f62a.png"
  ],
  [
    "1f356",
    "1f52e",
    "20220506/u1f52e/u1f52e_u1f356.png"
  ],
  [
    "1f32c",
    "1fa84",
    "20220506/u1fa84/u1fa84_u1f32c-ufe0f.png"
  ],
  [
    "1f3cf",
    "1f493",
    "20220203/u1f493/u1f493_u1f3cf.png"
  ],
  [
    "1fa98",
    "1f937",
    "20220815/u1f937/u1f937_u1fa98.png"
  ],
  [
    "1f959",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u1f959.png"
  ],
  [
    "1f61d",
    "1f608",
    "20201001/u1f61d/u1f61d_u1f608.png"
  ],
  [
    "1f632",
    "1f613",
    "20201001/u1f632/u1f632_u1f613.png"
  ],
  [
    "1f94e",
    "1f96b",
    "20230418/u1f96b/u1f96b_u1f94e.png"
  ],
  [
    "1f33a",
    "1f31f",
    "20230127/u1f31f/u1f31f_u1f33a.png"
  ],
  [
    "1f34b",
    "1f33c",
    "20230127/u1f34b/u1f34b_u1f33c.png"
  ],
  [
    "1f35e",
    "1f382",
    "20230127/u1f35e/u1f35e_u1f382.png"
  ],
  [
    "1f96c",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1f96c.png"
  ],
  [
    "1f604",
    "1f573",
    "20201001/u1f604/u1f604_u1f573-ufe0f.png"
  ],
  [
    "1fabb",
    "1f495",
    "20230418/u1f495/u1f495_u1fabb.png"
  ],
  [
    "1f963",
    "1f493",
    "20220203/u1f493/u1f493_u1f963.png"
  ],
  [
    "26ce",
    "1f60d",
    "20220203/u1f60d/u1f60d_u26ce.png"
  ],
  [
    "1fa7a",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1fa7a.png"
  ],
  [
    "1fab9",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1fab9.png"
  ],
  [
    "2795",
    "1f381",
    "20220506/u1f381/u1f381_u2795.png"
  ],
  [
    "1f9c6",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f9c6.png"
  ],
  [
    "1f4a9",
    "1fae0",
    "20211115/u1fae0/u1fae0_u1f4a9.png"
  ],
  [
    "1f614",
    "1f94e",
    "20220815/u1f614/u1f614_u1f94e.png"
  ],
  [
    "1f3a8",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f3a8.png"
  ],
  [
    "1f9d0",
    "1f436",
    "20211115/u1f436/u1f436_u1f9d0.png"
  ],
  [
    "1f9e6",
    "1f614",
    "20220815/u1f614/u1f614_u1f9e6.png"
  ],
  [
    "1f98a",
    "1f426",
    "20221101/u1f98a/u1f98a_u1f426.png"
  ],
  [
    "1f61c",
    "1f40c",
    "20210218/u1f40c/u1f40c_u1f61c.png"
  ],
  [
    "1f40c",
    "1f618",
    "20230216/u1f40c/u1f40c_u1f618.png"
  ],
  [
    "1fad1",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1fad1.png"
  ],
  [
    "1f427",
    "1f48b",
    "20211115/u1f427/u1f427_u1f48b.png"
  ],
  [
    "1f3b9",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f3b9.png"
  ],
  [
    "1f4ab",
    "1f970",
    "20201001/u1f970/u1f970_u1f4ab.png"
  ],
  [
    "1f607",
    "1f99d",
    "20211115/u1f99d/u1f99d_u1f607.png"
  ],
  [
    "1f393",
    "1f47b",
    "20230418/u1f47b/u1f47b_u1f393.png"
  ],
  [
    "1f6e0",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f6e0-ufe0f.png"
  ],
  [
    "1f39e",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f39e-ufe0f.png"
  ],
  [
    "1f947",
    "1f381",
    "20230127/u1f381/u1f381_u1f947.png"
  ],
  [
    "1f4da",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f4da.png"
  ],
  [
    "1f31c",
    "1f98a",
    "20221101/u1f98a/u1f98a_u1f31c.png"
  ],
  [
    "1f381",
    "1f49d",
    "20211115/u1f381/u1f381_u1f49d.png"
  ],
  [
    "1f9b7",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f9b7.png"
  ],
  [
    "1f9cb",
    "1f493",
    "20220203/u1f493/u1f493_u1f9cb.png"
  ],
  [
    "1f38a",
    "1f393",
    "20230418/u1f38a/u1f38a_u1f393.png"
  ],
  [
    "1f61d",
    "1f978",
    "20201001/u1f61d/u1f61d_u1f978.png"
  ],
  [
    "1f62d",
    "1f525",
    "20201001/u1f62d/u1f62d_u1f525.png"
  ],
  [
    "1f680",
    "1f495",
    "20220203/u1f495/u1f495_u1f680.png"
  ],
  [
    "1f633",
    "1f30d",
    "20201001/u1f633/u1f633_u1f30d.png"
  ],
  [
    "1f609",
    "1f9c1",
    "20230301/u1f609/u1f609_u1f9c1.png"
  ],
  [
    "1f6cb",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f6cb-ufe0f.png"
  ],
  [
    "1f925",
    "1f410",
    "20210831/u1f410/u1f410_u1f925.png"
  ],
  [
    "1f480",
    "1f925",
    "20201001/u1f925/u1f925_u1f480.png"
  ],
  [
    "2660",
    "1f618",
    "20220506/u1f618/u1f618_u2660-ufe0f.png"
  ],
  [
    "1f96f",
    "1f614",
    "20220815/u1f614/u1f614_u1f96f.png"
  ],
  [
    "1f427",
    "1f31e",
    "20211115/u1f427/u1f427_u1f31e.png"
  ],
  [
    "1fa95",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1fa95.png"
  ],
  [
    "1f3e0",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f3e0.png"
  ],
  [
    "1f42e",
    "1f920",
    "20230803/u1f42e/u1f42e_u1f920.png"
  ],
  [
    "1f604",
    "1f612",
    "20201001/u1f604/u1f604_u1f612.png"
  ],
  [
    "1f345",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f345.png"
  ],
  [
    "231b",
    "1f48b",
    "20230418/u1f48b/u1f48b_u231b.png"
  ],
  [
    "1f610",
    "1f633",
    "20201001/u1f633/u1f633_u1f610.png"
  ],
  [
    "1f5a4",
    "1f31b",
    "20201001/u1f5a4/u1f5a4_u1f31b.png"
  ],
  [
    "1f34d",
    "1f602",
    "20201001/u1f602/u1f602_u1f34d.png"
  ],
  [
    "1f988",
    "1f609",
    "20230418/u1f988/u1f988_u1f609.png"
  ],
  [
    "1f340",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f340.png"
  ],
  [
    "1f925",
    "263a",
    "20201001/u263a-ufe0f/u263a-ufe0f_u1f925.png"
  ],
  [
    "1f628",
    "1f972",
    "20201001/u1f628/u1f628_u1f972.png"
  ],
  [
    "1f952",
    "1f493",
    "20220203/u1f493/u1f493_u1f952.png"
  ],
  [
    "1f96d",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f96d.png"
  ],
  [
    "1fad3",
    "1f381",
    "20220506/u1f381/u1f381_u1fad3.png"
  ],
  [
    "264f",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u264f.png"
  ],
  [
    "1f60d",
    "2753",
    "20220203/u1f60d/u1f60d_u2753.png"
  ],
  [
    "1f34d",
    "1f602",
    "20201001/u1f602/u1f602_u1f34d.png"
  ],
  [
    "1f41f",
    "1fa84",
    "20210831/u1f41f/u1f41f_u1fa84.png"
  ],
  [
    "1f36b",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f36b.png"
  ],
  [
    "3030",
    "1f495",
    "20230216/u1f495/u1f495_u3030-ufe0f.png"
  ],
  [
    "1f4f1",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f4f1.png"
  ],
  [
    "1f644",
    "1f61a",
    "20201001/u1f61a/u1f61a_u1f644.png"
  ],
  [
    "00ae",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u00ae-ufe0f.png"
  ],
  [
    "1f6f8",
    "1f358",
    "20230418/u1f6f8/u1f6f8_u1f358.png"
  ],
  [
    "1f623",
    "1f31f",
    "20201001/u1f623/u1f623_u1f31f.png"
  ],
  [
    "1f92b",
    "1f920",
    "20201001/u1f92b/u1f92b_u1f920.png"
  ],
  [
    "1f4ca",
    "1fa84",
    "20230418/u1fa84/u1fa84_u1f4ca.png"
  ],
  [
    "1f47b",
    "1f9ff",
    "20220506/u1f47b/u1f47b_u1f9ff.png"
  ],
  [
    "2665",
    "265f",
    "20220506/u2665-ufe0f/u2665-ufe0f_u265f-ufe0f.png"
  ],
  [
    "1f630",
    "2639",
    "20201001/u2639-ufe0f/u2639-ufe0f_u1f630.png"
  ],
  [
    "1f6f9",
    "1f381",
    "20230127/u1f381/u1f381_u1f6f9.png"
  ],
  [
    "1f307",
    "26c4",
    "20230127/u1f307/u1f307_u26c4.png"
  ],
  [
    "1f37c",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1f37c.png"
  ],
  [
    "26bd",
    "1f62d",
    "20220406/u26bd/u26bd_u1f62d.png"
  ],
  [
    "1f9c2",
    "1f495",
    "20220203/u1f495/u1f495_u1f9c2.png"
  ],
  [
    "1f52c",
    "1f602",
    "20230418/u1f602/u1f602_u1f52c.png"
  ],
  [
    "1f441",
    "1f634",
    "20201001/u1f634/u1f634_u1f441-ufe0f.png"
  ],
  [
    "00ae",
    "1f31f",
    "20220203/u1f31f/u1f31f_u00ae-ufe0f.png"
  ],
  [
    "1f4cf",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1f4cf.png"
  ],
  [
    "1f43c",
    "1f49d",
    "20230216/u1f49d/u1f49d_u1f43c.png"
  ],
  [
    "1f96d",
    "1f96b",
    "20230418/u1f96b/u1f96b_u1f96d.png"
  ],
  [
    "1f62d",
    "1f622",
    "20201001/u1f62d/u1f62d_u1f622.png"
  ],
  [
    "1f965",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f965.png"
  ],
  [
    "1f61b",
    "1f607",
    "20201001/u1f61b/u1f61b_u1f607.png"
  ],
  [
    "1fa77",
    "1f58d",
    "20230301/u1fa77/u1fa77_u1f58d-ufe0f.png"
  ],
  [
    "2049",
    "1f52e",
    "20220506/u1f52e/u1f52e_u2049-ufe0f.png"
  ],
  [
    "1f3d3",
    "1f618",
    "20230301/u1f618/u1f618_u1f3d3.png"
  ],
  [
    "1f9c4",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f9c4.png"
  ],
  [
    "1fa84",
    "1f9fc",
    "20220506/u1fa84/u1fa84_u1f9fc.png"
  ],
  [
    "1f3c5",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f3c5.png"
  ],
  [
    "1f451",
    "1f607",
    "20201001/u1f607/u1f607_u1f451.png"
  ],
  [
    "1faa9",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1faa9.png"
  ],
  [
    "1f61f",
    "1f617",
    "20201001/u1f617/u1f617_u1f61f.png"
  ],
  [
    "1f972",
    "1f929",
    "20230301/u1f929/u1f929_u1f972.png"
  ],
  [
    "23f0",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u23f0.png"
  ],
  [
    "1f624",
    "1f577",
    "20201001/u1f624/u1f624_u1f577-ufe0f.png"
  ],
  [
    "1fab3",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1fab3.png"
  ],
  [
    "1f616",
    "1f634",
    "20201001/u1f616/u1f616_u1f634.png"
  ],
  [
    "1f643",
    "1f984",
    "20210831/u1f984/u1f984_u1f643.png"
  ],
  [
    "1f9ea",
    "1f498",
    "20230418/u1f498/u1f498_u1f9ea.png"
  ],
  [
    "1f62f",
    "1f4f0",
    "20230301/u1f62f/u1f62f_u1f4f0.png"
  ],
  [
    "1f50c",
    "1f60e",
    "20230418/u1f60e/u1f60e_u1f50c.png"
  ],
  [
    "1f47f",
    "1f52e",
    "20201001/u1f47f/u1f47f_u1f52e.png"
  ],
  [
    "1fadb",
    "1f48b",
    "20230418/u1f48b/u1f48b_u1fadb.png"
  ],
  [
    "1f950",
    "1f386",
    "20230418/u1f386/u1f386_u1f950.png"
  ],
  [
    "2753",
    "1f47b",
    "20220815/u1f47b/u1f47b_u2753.png"
  ],
  [
    "1f451",
    "1f9a5",
    "20201001/u1f9a5/u1f9a5_u1f451.png"
  ],
  [
    "1f961",
    "1f970",
    "20230418/u1f970/u1f970_u1f961.png"
  ],
  [
    "1f47b",
    "1f982",
    "20210218/u1f982/u1f982_u1f47b.png"
  ],
  [
    "1f608",
    "1f61f",
    "20201001/u1f61f/u1f61f_u1f608.png"
  ],
  [
    "1f41d",
    "1f61d",
    "20201001/u1f61d/u1f61d_u1f41d.png"
  ],
  [
    "262e",
    "1f495",
    "20230216/u1f495/u1f495_u262e-ufe0f.png"
  ],
  [
    "1f495",
    "1f924",
    "20201001/u1f924/u1f924_u1f495.png"
  ],
  [
    "1f33c",
    "1f31c",
    "20230127/u1f31c/u1f31c_u1f33c.png"
  ],
  [
    "1f958",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f958.png"
  ],
  [
    "1f98c",
    "1f92e",
    "20201001/u1f92e/u1f92e_u1f98c.png"
  ],
  [
    "1f58a",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f58a-ufe0f.png"
  ],
  [
    "1f98a",
    "2639",
    "20221101/u1f98a/u1f98a_u2639-ufe0f.png"
  ],
  [
    "1f600",
    "1fae5",
    "20230301/u1fae5/u1fae5_u1f600.png"
  ],
  [
    "1fa92",
    "1fa84",
    "20230418/u1fa84/u1fa84_u1fa92.png"
  ],
  [
    "1f31d",
    "1f97a",
    "20230301/u1f97a/u1f97a_u1f31d.png"
  ],
  [
    "1f636",
    "1f978",
    "20201001/u1f636/u1f636_u1f978.png"
  ],
  [
    "1f965",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f965.png"
  ],
  [
    "1f94f",
    "1f495",
    "20230216/u1f495/u1f495_u1f94f.png"
  ],
  [
    "1f613",
    "1f628",
    "20201001/u1f628/u1f628_u1f613.png"
  ],
  [
    "1fadb",
    "1f493",
    "20230418/u1f493/u1f493_u1fadb.png"
  ],
  [
    "2648",
    "1f96b",
    "20230418/u1f96b/u1f96b_u2648.png"
  ],
  [
    "1fab3",
    "1f97a",
    "20230301/u1f97a/u1f97a_u1fab3.png"
  ],
  [
    "1f431",
    "1f613",
    "20230301/u1f613/u1f613_u1f431.png"
  ],
  [
    "1f953",
    "1f602",
    "20230418/u1f602/u1f602_u1f953.png"
  ],
  [
    "1f6e0",
    "1f498",
    "20220506/u1f498/u1f498_u1f6e0-ufe0f.png"
  ],
  [
    "1f197",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f197.png"
  ],
  [
    "1f92f",
    "1f611",
    "20201001/u1f92f/u1f92f_u1f611.png"
  ],
  [
    "1f618",
    "1f916",
    "20201001/u1f618/u1f618_u1f916.png"
  ],
  [
    "1f9f3",
    "1f493",
    "20230418/u1f493/u1f493_u1f9f3.png"
  ],
  [
    "1f3b4",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f3b4.png"
  ],
  [
    "1f39e",
    "1f381",
    "20220506/u1f381/u1f381_u1f39e-ufe0f.png"
  ],
  [
    "00a9",
    "1f498",
    "20220203/u1f498/u1f498_u00a9-ufe0f.png"
  ],
  [
    "2122",
    "1f31f",
    "20220203/u1f31f/u1f31f_u2122-ufe0f.png"
  ],
  [
    "1f360",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1f360.png"
  ],
  [
    "1f512",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f512.png"
  ],
  [
    "1f3bb",
    "1f3b0",
    "20230126/u1f3b0/u1f3b0_u1f3bb.png"
  ],
  [
    "1f9cb",
    "1f495",
    "20220203/u1f495/u1f495_u1f9cb.png"
  ],
  [
    "1f350",
    "1f493",
    "20220203/u1f493/u1f493_u1f350.png"
  ],
  [
    "1f96f",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f96f.png"
  ],
  [
    "1f98a",
    "1f917",
    "20221101/u1f98a/u1f98a_u1f917.png"
  ],
  [
    "1f643",
    "1f42d",
    "20201001/u1f643/u1f643_u1f42d.png"
  ],
  [
    "1fa7a",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1fa7a.png"
  ],
  [
    "1f911",
    "1f616",
    "20201001/u1f616/u1f616_u1f911.png"
  ],
  [
    "2708",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u2708-ufe0f.png"
  ],
  [
    "1f3a1",
    "1f38a",
    "20230127/u1f38a/u1f38a_u1f3a1.png"
  ],
  [
    "1f9f3",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f9f3.png"
  ],
  [
    "1f626",
    "1f60d",
    "20201001/u1f60d/u1f60d_u1f626.png"
  ],
  [
    "1f4df",
    "1f386",
    "20230418/u1f386/u1f386_u1f4df.png"
  ],
  [
    "1f369",
    "2763",
    "20230301/u2763-ufe0f/u2763-ufe0f_u1f369.png"
  ],
  [
    "1f615",
    "1f33c",
    "20230301/u1f615/u1f615_u1f33c.png"
  ],
  [
    "1f969",
    "1f937",
    "20220815/u1f937/u1f937_u1f969.png"
  ],
  [
    "1f922",
    "2665",
    "20201001/u1f922/u1f922_u2665-ufe0f.png"
  ],
  [
    "1fa83",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1fa83.png"
  ],
  [
    "1f96c",
    "1f38a",
    "20230418/u1f38a/u1f38a_u1f96c.png"
  ],
  [
    "1f496",
    "1f495",
    "20201001/u1f496/u1f496_u1f495.png"
  ],
  [
    "1f375",
    "1f937",
    "20220815/u1f937/u1f937_u1f375.png"
  ],
  [
    "1f9a5",
    "1f605",
    "20201001/u1f605/u1f605_u1f9a5.png"
  ],
  [
    "1f626",
    "1f308",
    "20201001/u1f626/u1f626_u1f308.png"
  ],
  [
    "1f364",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f364.png"
  ],
  [
    "1f40c",
    "1f619",
    "20230216/u1f40c/u1f40c_u1f619.png"
  ],
  [
    "1f47f",
    "1f431",
    "20201001/u1f47f/u1f47f_u1f431.png"
  ],
  [
    "1f50b",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f50b.png"
  ],
  [
    "27bf",
    "1f60d",
    "20220203/u1f60d/u1f60d_u27bf.png"
  ],
  [
    "1f410",
    "1f611",
    "20210831/u1f410/u1f410_u1f611.png"
  ],
  [
    "1f3bd",
    "1f618",
    "20220203/u1f618/u1f618_u1f3bd.png"
  ],
  [
    "1f344",
    "1f60d",
    "20220406/u1f344/u1f344_u1f60d.png"
  ],
  [
    "1f48d",
    "1f97a",
    "20230418/u1f97a/u1f97a_u1f48d.png"
  ],
  [
    "1f0cf",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f0cf.png"
  ],
  [
    "1f490",
    "1f975",
    "20201001/u1f975/u1f975_u1f490.png"
  ],
  [
    "1f39f",
    "1f602",
    "20220815/u1f602/u1f602_u1f39f-ufe0f.png"
  ],
  [
    "1fabf",
    "1f4a9",
    "20221101/u1fabf/u1fabf_u1f4a9.png"
  ],
  [
    "1f31e",
    "1f451",
    "20230127/u1f31e/u1f31e_u1f451.png"
  ],
  [
    "1f34d",
    "1f37d",
    "20230127/u1f34d/u1f34d_u1f37d-ufe0f.png"
  ],
  [
    "1f512",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u1f512.png"
  ],
  [
    "1f981",
    "1f988",
    "20230418/u1f988/u1f988_u1f981.png"
  ],
  [
    "26c4",
    "1f31e",
    "20230127/u1f31e/u1f31e_u26c4.png"
  ],
  [
    "26d3",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u26d3-ufe0f.png"
  ],
  [
    "1f31b",
    "2764",
    "20201001/u2764-ufe0f/u2764-ufe0f_u1f31b.png"
  ],
  [
    "1f41a",
    "1fa84",
    "20220506/u1fa84/u1fa84_u1f41a.png"
  ],
  [
    "1fab4",
    "1f498",
    "20220203/u1f498/u1f498_u1fab4.png"
  ],
  [
    "1f90d",
    "1f525",
    "20201001/u1f90d/u1f90d_u1f525.png"
  ],
  [
    "1f3f9",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f3f9.png"
  ],
  [
    "1f497",
    "1f911",
    "20201001/u1f911/u1f911_u1f497.png"
  ],
  [
    "2122",
    "1f970",
    "20220203/u1f970/u1f970_u2122-ufe0f.png"
  ],
  [
    "1f43b",
    "1f414",
    "20230126/u1f414/u1f414_u1f43b.png"
  ],
  [
    "1f368",
    "1f31f",
    "20220506/u1f31f/u1f31f_u1f368.png"
  ],
  [
    "1f3ba",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1f3ba.png"
  ],
  [
    "1f616",
    "1f609",
    "20201001/u1f609/u1f609_u1f616.png"
  ],
  [
    "264d",
    "1faa4",
    "20230418/u1faa4/u1faa4_u264d.png"
  ],
  [
    "1f988",
    "1f636",
    "20230418/u1f988/u1f988_u1f636.png"
  ],
  [
    "1f35d",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f35d.png"
  ],
  [
    "1fab3",
    "1f97a",
    "20230301/u1f97a/u1f97a_u1fab3.png"
  ],
  [
    "1fa76",
    "1f429",
    "20221101/u1fa76/u1fa76_u1f429.png"
  ],
  [
    "1f680",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f680.png"
  ],
  [
    "1f497",
    "1f49b",
    "20201001/u1f49b/u1f49b_u1f497.png"
  ],
  [
    "1f929",
    "1f630",
    "20201001/u1f929/u1f929_u1f630.png"
  ],
  [
    "1f978",
    "1f912",
    "20201001/u1f912/u1f912_u1f978.png"
  ],
  [
    "1f612",
    "1f337",
    "20201001/u1f612/u1f612_u1f337.png"
  ],
  [
    "1fa90",
    "1f602",
    "20220506/u1f602/u1f602_u1fa90.png"
  ],
  [
    "1f383",
    "1f422",
    "20201001/u1f422/u1f422_u1f383.png"
  ],
  [
    "1f620",
    "1f625",
    "20201001/u1f625/u1f625_u1f620.png"
  ],
  [
    "1f441",
    "1f437",
    "20230216/u1f437/u1f437_u1f441-ufe0f.png"
  ],
  [
    "1f494",
    "1f31e",
    "20230127/u1f31e/u1f31e_u1f494.png"
  ],
  [
    "1f94f",
    "1f970",
    "20220203/u1f970/u1f970_u1f94f.png"
  ],
  [
    "1fabb",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1fabb.png"
  ],
  [
    "1f382",
    "1f335",
    "20230127/u1f335/u1f335_u1f382.png"
  ],
  [
    "2122",
    "1f97a",
    "20220815/u1f97a/u1f97a_u2122-ufe0f.png"
  ],
  [
    "1f48e",
    "1f61d",
    "20230301/u1f61d/u1f61d_u1f48e.png"
  ],
  [
    "1faa8",
    "1f3c0",
    "20230126/u1f3c0/u1f3c0_u1faa8.png"
  ],
  [
    "1f0cf",
    "1f5d1",
    "20230418/u1f5d1-ufe0f/u1f5d1-ufe0f_u1f0cf.png"
  ],
  [
    "1f61c",
    "1f451",
    "20201001/u1f61c/u1f61c_u1f451.png"
  ],
  [
    "1f60d",
    "1f5a4",
    "20201001/u1f60d/u1f60d_u1f5a4.png"
  ],
  [
    "1f58c",
    "1f493",
    "20220203/u1f493/u1f493_u1f58c-ufe0f.png"
  ],
  [
    "1f4df",
    "1f498",
    "20230418/u1f498/u1f498_u1f4df.png"
  ],
  [
    "267e",
    "1f618",
    "20220203/u1f618/u1f618_u267e-ufe0f.png"
  ],
  [
    "1f52d",
    "1f498",
    "20230418/u1f498/u1f498_u1f52d.png"
  ],
  [
    "1f373",
    "1f495",
    "20220506/u1f495/u1f495_u1f373.png"
  ],
  [
    "1f958",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f958.png"
  ],
  [
    "1f49e",
    "1fae4",
    "20211115/u1fae4/u1fae4_u1f49e.png"
  ],
  [
    "1f61d",
    "1f344",
    "20220406/u1f344/u1f344_u1f61d.png"
  ],
  [
    "1f940",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f940.png"
  ],
  [
    "1f98c",
    "1f495",
    "20201001/u1f495/u1f495_u1f98c.png"
  ],
  [
    "1f9f9",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1f9f9.png"
  ],
  [
    "1f605",
    "1f3a7",
    "20230127/u1f3a7/u1f3a7_u1f605.png"
  ],
  [
    "1f379",
    "1f614",
    "20230301/u1f614/u1f614_u1f379.png"
  ],
  [
    "1f6f7",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f6f7.png"
  ],
  [
    "2650",
    "1f52e",
    "20220506/u1f52e/u1f52e_u2650.png"
  ],
  [
    "1fabb",
    "1f3b0",
    "20230418/u1f3b0/u1f3b0_u1fabb.png"
  ],
  [
    "1f3bb",
    "1f614",
    "20220815/u1f614/u1f614_u1f3bb.png"
  ],
  [
    "1f3b2",
    "1f32a",
    "20230418/u1f32a-ufe0f/u1f32a-ufe0f_u1f3b2.png"
  ],
  [
    "1f4bf",
    "1f937",
    "20220815/u1f937/u1f937_u1f4bf.png"
  ],
  [
    "1f62e",
    "1f433",
    "20230418/u1f433/u1f433_u1f62e.png"
  ],
  [
    "1fa7a",
    "1f493",
    "20230418/u1f493/u1f493_u1fa7a.png"
  ],
  [
    "1f330",
    "1f52e",
    "20220506/u1f52e/u1f52e_u1f330.png"
  ],
  [
    "1f35d",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f35d.png"
  ],
  [
    "1f913",
    "26c4",
    "20230301/u1f913/u1f913_u26c4.png"
  ],
  [
    "1f386",
    "1f197",
    "20230418/u1f386/u1f386_u1f197.png"
  ],
  [
    "1fada",
    "1f493",
    "20230418/u1f493/u1f493_u1fada.png"
  ],
  [
    "1f3d5",
    "1f970",
    "20230301/u1f970/u1f970_u1f3d5-ufe0f.png"
  ],
  [
    "1f62d",
    "1f994",
    "20201001/u1f62d/u1f62d_u1f994.png"
  ],
  [
    "1f976",
    "1f915",
    "20230301/u1f915/u1f915_u1f976.png"
  ],
  [
    "1fa75",
    "1f49f",
    "20221101/u1fa75/u1fa75_u1f49f.png"
  ],
  [
    "1f348",
    "1f945",
    "20230418/u1f945/u1f945_u1f348.png"
  ],
  [
    "1f4a1",
    "1f961",
    "20230418/u1f4a1/u1f4a1_u1f961.png"
  ],
  [
    "1f967",
    "1f614",
    "20230418/u1f614/u1f614_u1f967.png"
  ],
  [
    "1f911",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f911.png"
  ],
  [
    "1f3d0",
    "2665",
    "20230301/u2665-ufe0f/u2665-ufe0f_u1f3d0.png"
  ],
  [
    "1f3d2",
    "1f937",
    "20220815/u1f937/u1f937_u1f3d2.png"
  ],
  [
    "1f333",
    "1f498",
    "20220506/u1f498/u1f498_u1f333.png"
  ],
  [
    "1fab2",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1fab2.png"
  ],
  [
    "1f628",
    "1f490",
    "20201001/u1f628/u1f628_u1f490.png"
  ],
  [
    "1f625",
    "1f31c",
    "20201001/u1f625/u1f625_u1f31c.png"
  ],
  [
    "1f396",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f396-ufe0f.png"
  ],
  [
    "1f4ab",
    "1f36c",
    "20230127/u1f36c/u1f36c_u1f4ab.png"
  ],
  [
    "1f969",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f969.png"
  ],
  [
    "1f44d",
    "26c4",
    "20220815/u1f44d/u1f44d_u26c4.png"
  ],
  [
    "1f644",
    "1f49d",
    "20201001/u1f644/u1f644_u1f49d.png"
  ],
  [
    "1f3c9",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f3c9.png"
  ],
  [
    "1f353",
    "1f338",
    "20230127/u1f353/u1f353_u1f338.png"
  ],
  [
    "231b",
    "1f945",
    "20230418/u1f945/u1f945_u231b.png"
  ],
  [
    "1f349",
    "1f9c0",
    "20220406/u1f349/u1f349_u1f9c0.png"
  ],
  [
    "1f3b7",
    "1f47b",
    "20220815/u1f47b/u1f47b_u1f3b7.png"
  ],
  [
    "1fab7",
    "1f498",
    "20220506/u1f498/u1f498_u1fab7.png"
  ],
  [
    "1f41f",
    "1f971",
    "20210831/u1f41f/u1f41f_u1f971.png"
  ],
  [
    "1f957",
    "1f602",
    "20220815/u1f602/u1f602_u1f957.png"
  ],
  [
    "1fac2",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1fac2.png"
  ],
  [
    "270f",
    "1f47b",
    "20220815/u1f47b/u1f47b_u270f-ufe0f.png"
  ],
  [
    "1f52c",
    "1f60d",
    "20230418/u1f60d/u1f60d_u1f52c.png"
  ],
  [
    "1f961",
    "1fa84",
    "20230418/u1fa84/u1fa84_u1f961.png"
  ],
  [
    "1f39b",
    "1f618",
    "20230418/u1f618/u1f618_u1f39b-ufe0f.png"
  ],
  [
    "1f627",
    "1f981",
    "20201001/u1f627/u1f627_u1f981.png"
  ],
  [
    "1f331",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f331.png"
  ],
  [
    "1f4f7",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f4f7.png"
  ],
  [
    "1f635",
    "1f9a5",
    "20201001/u1f635/u1f635_u1f9a5.png"
  ],
  [
    "1f94a",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f94a.png"
  ],
  [
    "1f381",
    "1f3af",
    "20220506/u1f381/u1f381_u1f3af.png"
  ],
  [
    "1f958",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f958.png"
  ],
  [
    "1f33b",
    "1fa84",
    "20220506/u1fa84/u1fa84_u1f33b.png"
  ],
  [
    "270f",
    "1f614",
    "20220815/u1f614/u1f614_u270f-ufe0f.png"
  ],
  [
    "2650",
    "1f495",
    "20220203/u1f495/u1f495_u2650.png"
  ],
  [
    "2653",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u2653.png"
  ],
  [
    "1f508",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f508.png"
  ],
  [
    "1f4af",
    "1f636",
    "20220815/u1f636/u1f636_u1f4af.png"
  ],
  [
    "1f347",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f347.png"
  ],
  [
    "1faa1",
    "1f97a",
    "20220815/u1f97a/u1f97a_u1faa1.png"
  ],
  [
    "1f937",
    "1f969",
    "20220815/u1f937/u1f937_u1f969.png"
  ],
  [
    "1f4f7",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f4f7.png"
  ],
  [
    "2639",
    "1f981",
    "20201001/u2639-ufe0f/u2639-ufe0f_u1f981.png"
  ],
  [
    "1f193",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f193.png"
  ],
  [
    "23f3",
    "1f493",
    "20230418/u1f493/u1f493_u23f3.png"
  ],
  [
    "270f",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u270f-ufe0f.png"
  ],
  [
    "1f3bb",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f3bb.png"
  ],
  [
    "1faab",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1faab.png"
  ],
  [
    "1f32d",
    "1f60b",
    "20201001/u1f60b/u1f60b_u1f32d.png"
  ],
  [
    "1f3b2",
    "1fa84",
    "20220815/u1fa84/u1fa84_u1f3b2.png"
  ],
  [
    "1f40c",
    "1f382",
    "20230216/u1f40c/u1f40c_u1f382.png"
  ],
  [
    "1f912",
    "1f34c",
    "20211115/u1f34c/u1f34c_u1f912.png"
  ],
  [
    "231b",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u231b.png"
  ],
  [
    "1f9ea",
    "1f381",
    "20230426/u1f381/u1f381_u1f9ea.png"
  ],
  [
    "1f52e",
    "1f3a7",
    "20230127/u1f3a7/u1f3a7_u1f52e.png"
  ],
  [
    "1f436",
    "1f99d",
    "20211115/u1f99d/u1f99d_u1f436.png"
  ],
  [
    "1f37f",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f37f.png"
  ],
  [
    "1f5dd",
    "1f31f",
    "20220203/u1f31f/u1f31f_u1f5dd-ufe0f.png"
  ],
  [
    "27bf",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u27bf.png"
  ],
  [
    "1f3b4",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f3b4.png"
  ],
  [
    "1f380",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f380.png"
  ],
  [
    "1f43a",
    "1f308",
    "20221101/u1f43a/u1f43a_u1f308.png"
  ],
  [
    "270f",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u270f-ufe0f.png"
  ],
  [
    "1f3f5",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1f3f5-ufe0f.png"
  ],
  [
    "1f945",
    "1f4ca",
    "20230418/u1f945/u1f945_u1f4ca.png"
  ],
  [
    "1f3df",
    "2601",
    "20220506/u2601-ufe0f/u2601-ufe0f_u1f3df-ufe0f.png"
  ],
  [
    "1f54a",
    "1f381",
    "20230127/u1f381/u1f381_u1f54a-ufe0f.png"
  ],
  [
    "2653",
    "1f30a",
    "20230418/u1f30a/u1f30a_u2653.png"
  ],
  [
    "1f36b",
    "1f498",
    "20220203/u1f498/u1f498_u1f36b.png"
  ],
  [
    "26f3",
    "1f493",
    "20220203/u1f493/u1f493_u26f3.png"
  ],
  [
    "1f9a5",
    "1f624",
    "20201001/u1f624/u1f624_u1f9a5.png"
  ],
  [
    "1f496",
    "1f9e1",
    "20201001/u1f9e1/u1f9e1_u1f496.png"
  ],
  [
    "1f41d",
    "1f937",
    "20220815/u1f937/u1f937_u1f41d.png"
  ],
  [
    "1f49e",
    "1f49c",
    "20201001/u1f49c/u1f49c_u1f49e.png"
  ],
  [
    "1f35f",
    "1f60d",
    "20220203/u1f60d/u1f60d_u1f35f.png"
  ],
  [
    "1f47d",
    "1f431",
    "20201001/u1f47d/u1f47d_u1f431.png"
  ],
  [
    "1f4be",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f4be.png"
  ],
  [
    "2702",
    "1f60d",
    "20230418/u1f60d/u1f60d_u2702-ufe0f.png"
  ],
  [
    "1f618",
    "1f92f",
    "20201001/u1f618/u1f618_u1f92f.png"
  ],
  [
    "1f393",
    "2665",
    "20230418/u2665-ufe0f/u2665-ufe0f_u1f393.png"
  ],
  [
    "2602",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u2602-ufe0f.png"
  ],
  [
    "1f3b2",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f3b2.png"
  ],
  [
    "1f348",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f348.png"
  ],
  [
    "1f9f2",
    "2665",
    "20230418/u2665-ufe0f/u2665-ufe0f_u1f9f2.png"
  ],
  [
    "2604",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u2604-ufe0f.png"
  ],
  [
    "1fac1",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u1fac1.png"
  ],
  [
    "1f94a",
    "1f498",
    "20220203/u1f498/u1f498_u1f94a.png"
  ],
  [
    "1f31c",
    "1f48c",
    "20201001/u1f48c/u1f48c_u1f31c.png"
  ],
  [
    "1f525",
    "1f437",
    "20201001/u1f437/u1f437_u1f525.png"
  ],
  [
    "1f410",
    "1f441",
    "20210831/u1f410/u1f410_u1f441-ufe0f.png"
  ],
  [
    "1f31d",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f31d.png"
  ],
  [
    "1f987",
    "26c4",
    "20230301/u1f987/u1f987_u26c4.png"
  ],
  [
    "23f3",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u23f3.png"
  ],
  [
    "1f96c",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f96c.png"
  ],
  [
    "1f39b",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f39b-ufe0f.png"
  ],
  [
    "1faa4",
    "1f9b4",
    "20230418/u1faa4/u1faa4_u1f9b4.png"
  ],
  [
    "1f393",
    "1f381",
    "20230418/u1f381/u1f381_u1f393.png"
  ],
  [
    "1f587",
    "1f31f",
    "20230418/u1f31f/u1f31f_u1f587-ufe0f.png"
  ],
  [
    "1f4bb",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1f4bb.png"
  ],
  [
    "1f41e",
    "1f96b",
    "20230418/u1f96b/u1f96b_u1f41e.png"
  ],
  [
    "1f35b",
    "1f618",
    "20220203/u1f618/u1f618_u1f35b.png"
  ],
  [
    "1f369",
    "1f44d",
    "20220815/u1f44d/u1f44d_u1f369.png"
  ],
  [
    "1f36a",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f36a.png"
  ],
  [
    "1f514",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f514.png"
  ],
  [
    "1f4f7",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f4f7.png"
  ],
  [
    "1f616",
    "1fabf",
    "20221101/u1fabf/u1fabf_u1f616.png"
  ],
  [
    "1f41e",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f41e.png"
  ],
  [
    "1f58a",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f58a-ufe0f.png"
  ],
  [
    "2049",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u2049-ufe0f.png"
  ],
  [
    "1f193",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f193.png"
  ],
  [
    "1f9e9",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u1f9e9.png"
  ],
  [
    "262f",
    "1f493",
    "20220203/u1f493/u1f493_u262f-ufe0f.png"
  ],
  [
    "1f525",
    "1f621",
    "20201001/u1f621/u1f621_u1f525.png"
  ],
  [
    "1f61c",
    "1f61c",
    "20201001/u1f61c/u1f61c_u1f61c.png"
  ],
  [
    "1f340",
    "1f614",
    "20220815/u1f614/u1f614_u1f340.png"
  ],
  [
    "1f60a",
    "1f31e",
    "20201001/u1f60a/u1f60a_u1f31e.png"
  ],
  [
    "267e",
    "1fa84",
    "20220815/u1fa84/u1fa84_u267e-ufe0f.png"
  ],
  [
    "1f30c",
    "1f970",
    "20230418/u1f970/u1f970_u1f30c.png"
  ],
  [
    "1f60f",
    "1f31f",
    "20201001/u1f60f/u1f60f_u1f31f.png"
  ],
  [
    "1f9e0",
    "1f602",
    "20220815/u1f602/u1f602_u1f9e0.png"
  ],
  [
    "1f975",
    "1f976",
    "20201001/u1f975/u1f975_u1f976.png"
  ],
  [
    "1f641",
    "1f630",
    "20201001/u1f641/u1f641_u1f630.png"
  ],
  [
    "1f4ae",
    "1f618",
    "20220203/u1f618/u1f618_u1f4ae.png"
  ],
  [
    "1f92c",
    "1f994",
    "20230301/u1f92c/u1f92c_u1f994.png"
  ],
  [
    "1f35e",
    "1f3a7",
    "20230127/u1f35e/u1f35e_u1f3a7.png"
  ],
  [
    "1f4da",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f4da.png"
  ],
  [
    "264e",
    "1f48b",
    "20220815/u1f48b/u1f48b_u264e.png"
  ],
  [
    "1f622",
    "1f601",
    "20201001/u1f601/u1f601_u1f622.png"
  ],
  [
    "1f637",
    "1f438",
    "20230803/u1f438/u1f438_u1f637.png"
  ],
  [
    "1f4a1",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f4a1.png"
  ],
  [
    "1fae2",
    "1fae2",
    "20211115/u1fae2/u1fae2_u1fae2.png"
  ],
  [
    "1fab3",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1fab3.png"
  ],
  [
    "1f915",
    "1f437",
    "20230301/u1f915/u1f915_u1f437.png"
  ],
  [
    "1f48b",
    "1fa70",
    "20220815/u1f48b/u1f48b_u1fa70.png"
  ],
  [
    "1f914",
    "1f92d",
    "20201001/u1f914/u1f914_u1f92d.png"
  ],
  [
    "1f9b7",
    "1f614",
    "20220815/u1f614/u1f614_u1f9b7.png"
  ],
  [
    "1f94b",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f94b.png"
  ],
  [
    "1fa7a",
    "1fa84",
    "20230418/u1fa84/u1fa84_u1fa7a.png"
  ],
  [
    "1f608",
    "1f601",
    "20201001/u1f601/u1f601_u1f608.png"
  ],
  [
    "1fad4",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1fad4.png"
  ],
  [
    "26cf",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u26cf-ufe0f.png"
  ],
  [
    "1f988",
    "1f339",
    "20230418/u1f988/u1f988_u1f339.png"
  ],
  [
    "1f3b3",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f3b3.png"
  ],
  [
    "1f419",
    "1f4ab",
    "20201001/u1f4ab/u1f4ab_u1f419.png"
  ],
  [
    "1f381",
    "1f31d",
    "20230127/u1f381/u1f381_u1f31d.png"
  ],
  [
    "1f47d",
    "1f433",
    "20230418/u1f433/u1f433_u1f47d.png"
  ],
  [
    "1f333",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f333.png"
  ],
  [
    "1f60b",
    "1f43b",
    "20210831/u1f43b/u1f43b_u1f60b.png"
  ],
  [
    "1f512",
    "1f381",
    "20230127/u1f381/u1f381_u1f512.png"
  ],
  [
    "270f",
    "1f30a",
    "20230418/u1f30a/u1f30a_u270f-ufe0f.png"
  ],
  [
    "1f3b9",
    "1f498",
    "20220203/u1f498/u1f498_u1f3b9.png"
  ],
  [
    "1f957",
    "1f97a",
    "20220815/u1f97a/u1f97a_u1f957.png"
  ],
  [
    "1f31e",
    "1f60a",
    "20201001/u1f60a/u1f60a_u1f31e.png"
  ],
  [
    "1fab4",
    "1f97a",
    "20220815/u1f97a/u1f97a_u1fab4.png"
  ],
  [
    "1f984",
    "26c4",
    "20210831/u1f984/u1f984_u26c4.png"
  ],
  [
    "2602",
    "1f970",
    "20220203/u1f970/u1f970_u2602-ufe0f.png"
  ],
  [
    "1f39f",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f39f-ufe0f.png"
  ],
  [
    "1f195",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f195.png"
  ],
  [
    "264a",
    "1f381",
    "20230127/u1f381/u1f381_u264a.png"
  ],
  [
    "1f496",
    "1f98a",
    "20221101/u1f98a/u1f98a_u1f496.png"
  ],
  [
    "1f35c",
    "1f495",
    "20220203/u1f495/u1f495_u1f35c.png"
  ],
  [
    "1f587",
    "1f381",
    "20230426/u1f381/u1f381_u1f587-ufe0f.png"
  ],
  [
    "1f195",
    "1fa84",
    "20220815/u1fa84/u1fa84_u1f195.png"
  ],
  [
    "1f62a",
    "1f607",
    "20201001/u1f62a/u1f62a_u1f607.png"
  ],
  [
    "270f",
    "1f381",
    "20230127/u1f381/u1f381_u270f-ufe0f.png"
  ],
  [
    "1fab3",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1fab3.png"
  ],
  [
    "1f9a9",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f9a9.png"
  ],
  [
    "1f62f",
    "1f62a",
    "20201001/u1f62f/u1f62f_u1f62a.png"
  ],
  [
    "1f610",
    "1f92e",
    "20201001/u1f92e/u1f92e_u1f610.png"
  ],
  [
    "1f3f5",
    "1f937",
    "20220815/u1f937/u1f937_u1f3f5-ufe0f.png"
  ],
  [
    "1f636",
    "1fab5",
    "20211115/u1fab5/u1fab5_u1f636.png"
  ],
  [
    "1f54a",
    "26a0",
    "20230418/u26a0-ufe0f/u26a0-ufe0f_u1f54a-ufe0f.png"
  ],
  [
    "1f33d",
    "1f3f8",
    "20230418/u1f3f8/u1f3f8_u1f33d.png"
  ],
  [
    "1f3b4",
    "1f495",
    "20220203/u1f495/u1f495_u1f3b4.png"
  ],
  [
    "1faa4",
    "1f5c3",
    "20230418/u1faa4/u1faa4_u1f5c3-ufe0f.png"
  ],
  [
    "1f437",
    "1f61e",
    "20230301/u1f61e/u1f61e_u1f437.png"
  ],
  [
    "264e",
    "1f52e",
    "20230301/u1f52e/u1f52e_u264e.png"
  ],
  [
    "1f976",
    "1f493",
    "20201001/u1f976/u1f976_u1f493.png"
  ],
  [
    "1f38a",
    "1f340",
    "20220815/u1f38a/u1f38a_u1f340.png"
  ],
  [
    "1faae",
    "1f47b",
    "20230613/u1f47b/u1f47b_u1faae.png"
  ],
  [
    "1f49e",
    "1f47d",
    "20201001/u1f47d/u1f47d_u1f49e.png"
  ],
  [
    "1f30a",
    "1f937",
    "20230418/u1f30a/u1f30a_u1f937.png"
  ],
  [
    "1f33f",
    "1f614",
    "20230301/u1f614/u1f614_u1f33f.png"
  ],
  [
    "1f347",
    "1f44d",
    "20230216/u1f44d/u1f44d_u1f347.png"
  ],
  [
    "1f9ec",
    "1f47b",
    "20230418/u1f47b/u1f47b_u1f9ec.png"
  ],
  [
    "1f3a1",
    "1f31f",
    "20220506/u1f31f/u1f31f_u1f3a1.png"
  ],
  [
    "1f3b9",
    "1fa84",
    "20220815/u1fa84/u1fa84_u1f3b9.png"
  ],
  [
    "1f508",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f508.png"
  ],
  [
    "1f916",
    "1f308",
    "20230301/u1f916/u1f916_u1f308.png"
  ],
  [
    "1f95e",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f95e.png"
  ],
  [
    "1f5a8",
    "2665",
    "20230418/u2665-ufe0f/u2665-ufe0f_u1f5a8-ufe0f.png"
  ],
  [
    "1f3a4",
    "1f493",
    "20220203/u1f493/u1f493_u1f3a4.png"
  ],
  [
    "1f333",
    "2601",
    "20220506/u2601-ufe0f/u2601-ufe0f_u1f333.png"
  ],
  [
    "1f96a",
    "1f327",
    "20221101/u1f327-ufe0f/u1f327-ufe0f_u1f96a.png"
  ],
  [
    "2652",
    "1f30a",
    "20230418/u1f30a/u1f30a_u2652.png"
  ],
  [
    "1f388",
    "1f601",
    "20201001/u1f601/u1f601_u1f388.png"
  ],
  [
    "1fad4",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1fad4.png"
  ],
  [
    "1fa83",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1fa83.png"
  ],
  [
    "1fae2",
    "1f31f",
    "20211115/u1fae2/u1fae2_u1f31f.png"
  ],
  [
    "1f3e0",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f3e0.png"
  ],
  [
    "2796",
    "1f618",
    "20220203/u1f618/u1f618_u2796.png"
  ],
  [
    "1f618",
    "1f414",
    "20230126/u1f414/u1f414_u1f618.png"
  ],
  [
    "1f359",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f359.png"
  ],
  [
    "264c",
    "1f602",
    "20220815/u1f602/u1f602_u264c.png"
  ],
  [
    "1f642",
    "1f648",
    "20201001/u1f642/u1f642_u1f648.png"
  ],
  [
    "1f50b",
    "1f970",
    "20220203/u1f970/u1f970_u1f50b.png"
  ],
  [
    "1f607",
    "1f629",
    "20201001/u1f629/u1f629_u1f607.png"
  ],
  [
    "1f50b",
    "1f498",
    "20220203/u1f498/u1f498_u1f50b.png"
  ],
  [
    "1f617",
    "1f52e",
    "20201001/u1f617/u1f617_u1f52e.png"
  ],
  [
    "1f35a",
    "1f614",
    "20220815/u1f614/u1f614_u1f35a.png"
  ],
  [
    "1f68c",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f68c.png"
  ],
  [
    "1f600",
    "1f617",
    "20201001/u1f600/u1f600_u1f617.png"
  ],
  [
    "1fada",
    "2763",
    "20230418/u2763-ufe0f/u2763-ufe0f_u1fada.png"
  ],
  [
    "1f30c",
    "1f97a",
    "20230418/u1f97a/u1f97a_u1f30c.png"
  ],
  [
    "1f47d",
    "1f307",
    "20230127/u1f307/u1f307_u1f47d.png"
  ],
  [
    "00ae",
    "2601",
    "20220203/u2601-ufe0f/u2601-ufe0f_u00ae-ufe0f.png"
  ],
  [
    "1f4bb",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f4bb.png"
  ],
  [
    "1f4ae",
    "1f970",
    "20220203/u1f970/u1f970_u1f4ae.png"
  ],
  [
    "1f608",
    "1f9c1",
    "20201001/u1f608/u1f608_u1f9c1.png"
  ],
  [
    "1f360",
    "2665",
    "20220506/u2665-ufe0f/u2665-ufe0f_u1f360.png"
  ],
  [
    "1f3c6",
    "1f60d",
    "20211115/u1f3c6/u1f3c6_u1f60d.png"
  ],
  [
    "2602",
    "1f386",
    "20230418/u1f386/u1f386_u2602-ufe0f.png"
  ],
  [
    "1f6f9",
    "1f495",
    "20220203/u1f495/u1f495_u1f6f9.png"
  ],
  [
    "262e",
    "1f381",
    "20230127/u1f381/u1f381_u262e-ufe0f.png"
  ],
  [
    "1f437",
    "1f929",
    "20230301/u1f929/u1f929_u1f437.png"
  ],
  [
    "1f6ae",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f6ae.png"
  ],
  [
    "1f92f",
    "1f973",
    "20230301/u1f92f/u1f92f_u1f973.png"
  ],
  [
    "1f38a",
    "1f9c0",
    "20201001/u1f38a/u1f38a_u1f9c0.png"
  ],
  [
    "1f31f",
    "1f624",
    "20201001/u1f624/u1f624_u1f31f.png"
  ],
  [
    "1f47d",
    "1faa8",
    "20220406/u1faa8/u1faa8_u1f47d.png"
  ],
  [
    "1f414",
    "1f36c",
    "20230126/u1f414/u1f414_u1f36c.png"
  ],
  [
    "1f5dd",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f5dd-ufe0f.png"
  ],
  [
    "1f976",
    "1f382",
    "20230301/u1f976/u1f976_u1f382.png"
  ],
  [
    "1f99e",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f99e.png"
  ],
  [
    "1f644",
    "1f629",
    "20201001/u1f644/u1f644_u1f629.png"
  ],
  [
    "1f987",
    "1f381",
    "20211115/u1f381/u1f381_u1f987.png"
  ],
  [
    "1faa4",
    "1fa84",
    "20230426/u1faa4/u1faa4_u1fa84.png"
  ],
  [
    "1f959",
    "1f30a",
    "20230418/u1f30a/u1f30a_u1f959.png"
  ],
  [
    "1f349",
    "1f913",
    "20220406/u1f349/u1f349_u1f913.png"
  ],
  [
    "1f3df",
    "1f381",
    "20220506/u1f381/u1f381_u1f3df-ufe0f.png"
  ],
  [
    "1fac2",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1fac2.png"
  ],
  [
    "1f925",
    "1f3a7",
    "20230127/u1f3a7/u1f3a7_u1f925.png"
  ],
  [
    "1f40c",
    "1f98c",
    "20210218/u1f40c/u1f40c_u1f98c.png"
  ],
  [
    "1f426",
    "1f42d",
    "20210831/u1f426/u1f426_u1f42d.png"
  ],
  [
    "1f94e",
    "1f96b",
    "20230418/u1f96b/u1f96b_u1f94e.png"
  ],
  [
    "1fac2",
    "1f37d",
    "20230418/u1f37d-ufe0f/u1f37d-ufe0f_u1fac2.png"
  ],
  [
    "1f33b",
    "1fa84",
    "20220506/u1fa84/u1fa84_u1f33b.png"
  ],
  [
    "1f697",
    "1f970",
    "20220203/u1f970/u1f970_u1f697.png"
  ],
  [
    "1f3f5",
    "1f386",
    "20230418/u1f386/u1f386_u1f3f5-ufe0f.png"
  ],
  [
    "1f680",
    "1f60e",
    "20220815/u1f60e/u1f60e_u1f680.png"
  ],
  [
    "1f4bf",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f4bf.png"
  ],
  [
    "1f441",
    "1f349",
    "20220406/u1f349/u1f349_u1f441-ufe0f.png"
  ],
  [
    "26a1",
    "1f945",
    "20230418/u1f945/u1f945_u26a1.png"
  ],
  [
    "1f441",
    "1f372",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f372.png"
  ],
  [
    "1f5a4",
    "1f337",
    "20201001/u1f5a4/u1f5a4_u1f337.png"
  ],
  [
    "1f3d3",
    "1f970",
    "20230301/u1f970/u1f970_u1f3d3.png"
  ],
  [
    "1f376",
    "1f52e",
    "20230418/u1f52e/u1f52e_u1f376.png"
  ],
  [
    "1f3bf",
    "1f52e",
    "20230301/u1f52e/u1f52e_u1f3bf.png"
  ],
  [
    "1f360",
    "1f602",
    "20220815/u1f602/u1f602_u1f360.png"
  ],
  [
    "1fa77",
    "1f498",
    "20230301/u1fa77/u1fa77_u1f498.png"
  ],
  [
    "1f327",
    "1f3de",
    "20230418/u1f327-ufe0f/u1f327-ufe0f_u1f3de-ufe0f.png"
  ],
  [
    "1f383",
    "1f43b",
    "20210831/u1f43b/u1f43b_u1f383.png"
  ],
  [
    "1f4a7",
    "1faa4",
    "20230418/u1faa4/u1faa4_u1f4a7.png"
  ],
  [
    "1f32e",
    "1f6f8",
    "20230418/u1f6f8/u1f6f8_u1f32e.png"
  ],
  [
    "1f937",
    "1f34c",
    "20230127/u1f34c/u1f34c_u1f937.png"
  ],
  [
    "1f353",
    "1f984",
    "20230127/u1f353/u1f353_u1f984.png"
  ],
  [
    "1f6fc",
    "1f38a",
    "20220815/u1f38a/u1f38a_u1f6fc.png"
  ],
  [
    "1f3e0",
    "1f5ef",
    "20230418/u1f5ef-ufe0f/u1f5ef-ufe0f_u1f3e0.png"
  ],
  [
    "1f4a1",
    "1f950",
    "20230418/u1f4a1/u1f4a1_u1f950.png"
  ],
  [
    "1f60f",
    "1f982",
    "20210218/u1f982/u1f982_u1f60f.png"
  ],
  [
    "1f60e",
    "1f922",
    "20230301/u1f922/u1f922_u1f60e.png"
  ],
  [
    "1f41a",
    "1f386",
    "20230418/u1f386/u1f386_u1f41a.png"
  ],
  [
    "1f197",
    "1f97a",
    "20220815/u1f97a/u1f97a_u1f197.png"
  ],
  [
    "2712",
    "1f618",
    "20230301/u1f618/u1f618_u2712-ufe0f.png"
  ],
  [
    "2049",
    "2763",
    "20220203/u2763-ufe0f/u2763-ufe0f_u2049-ufe0f.png"
  ],
  [
    "1fa98",
    "1f618",
    "20230301/u1f618/u1f618_u1fa98.png"
  ],
  [
    "1f48b",
    "1f490",
    "20201001/u1f48b/u1f48b_u1f490.png"
  ],
  [
    "1f30c",
    "1f60e",
    "20230418/u1f60e/u1f60e_u1f30c.png"
  ],
  [
    "1f36c",
    "1f620",
    "20230127/u1f36c/u1f36c_u1f620.png"
  ],
  [
    "1f952",
    "1f60d",
    "20230301/u1f60d/u1f60d_u1f952.png"
  ],
  [
    "1f441",
    "1f616",
    "20201001/u1f616/u1f616_u1f441-ufe0f.png"
  ],
  [
    "1f980",
    "1f4a1",
    "20230418/u1f4a1/u1f4a1_u1f980.png"
  ],
  [
    "1f974",
    "1fae0",
    "20211115/u1fae0/u1fae0_u1f974.png"
  ],
  [
    "2753",
    "1f618",
    "20220203/u1f618/u1f618_u2753.png"
  ],
  [
    "1f61b",
    "1f643",
    "20201001/u1f643/u1f643_u1f61b.png"
  ],
  [
    "1f37c",
    "1f441",
    "20230418/u1f441-ufe0f/u1f441-ufe0f_u1f37c.png"
  ],
  [
    "1fac0",
    "1f48b",
    "20220815/u1f48b/u1f48b_u1fac0.png"
  ]
]